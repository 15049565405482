import React, { useEffect, useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { createAccount } from "../../app.service";
import { useAppContext } from "../../AppContext";

const CreateAccount: React.FC<{ onClose: () => void, onSuccess: () => void }> = ({ onClose, onSuccess }) => {
  const { currentToken } = useAppContext();

  const [isOpen, setIsOpen] = useState(true);
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("Prospect");
  const [territory, setTerritory] = useState<string>("");
  const [status, setStatus] = useState<string>("Not Contacted");

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      onClose(); // Call the parent callback to handle close
    }
  };

  const handleSaveAccount = async () => {
    const accountObj = {
      name,
      type,
      territory,
      status,
    };

    if (currentToken) {
      // Create new account
      const create = await createAccount(accountObj, currentToken);
      if (create.success) {
        onSuccess(); // Call the success callback
        closeSheet();
      } else {
        console.error(create.message);
      }
    }
  };

  const closeSheet = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Sheet open={isOpen} onOpenChange={handleOpenChange}>
      <SheetContent side="right" className="w-[600px] !max-w-none">
        <SheetHeader className="h-full">
          <SheetTitle className="text-2xl mt-4">Create Account</SheetTitle>
          <div className="flex flex-col justify-between h-full pt-10">
            <div>
              <div className="flex flex-col gap-4 mt-6">
                <span className="flex flex-col gap-1 w-full">
                  <Label>Account Name</Label>
                  <Input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </span>
                <span className="flex flex-col gap-1 w-full">
                  <Label>Type</Label>
                  <select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="border px-4 py-2 rounded"
                  >
                    <option value="Prospect">Prospect</option>
                  </select>
                </span>
                <span className="flex flex-col gap-1 w-full">
                  <Label>Territory</Label>
                  <select
                    value={territory}
                    onChange={(e) => setTerritory(e.target.value)}
                    className="border px-4 py-2 rounded"
                  >
                    <option disabled value="">Select Territory..</option>
                    <option value="North America">North America</option>
                    <option value="EMEA">EMEA</option>
                    <option value="APAC">APAC</option>
                    <option value="LATAM">LATAM</option>
                  </select>
                </span>
                <span className="flex flex-col gap-1 w-full">
                  <Label>Status</Label>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="border px-4 py-2 rounded"
                  >
                    <option value="Not Contacted">Not Contacted</option>
                    <option value="Contacted">Contacted</option>
                    <option value="Disqualified">Disqualified</option>
                  </select>
                </span>
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleSaveAccount}
              >
                Add Account
              </button>
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded"
                onClick={closeSheet}
              >
                Close
              </button>
            </div>
          </div>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default CreateAccount;