import { jsPDF } from "jspdf";
import { formatNumberWithThousandsSeparator } from "../methods";
import logo from "../../assets/img/wordmark-logo-120x43.png";

interface Product {
  productId: number;
  productName: string;
  productType: string;
  quantity: number;
  salesPrice: number;
  overagePrice: number;
  revenueType: string;
  quantityUnitOfMeasure: string;
  isEditing: boolean;
  description: string;
  change: string | null;
}

interface BillingAddress {
  line1: string;
  line2: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
}

type ContractDetails = {
  type: "New Business" | "Up-sell" | "Renewal";
  clientName: string;
  billingAddress: BillingAddress;
  startDate: string;
  contractTerm: number;
  noticePeriod: number;
  autoRenewal: boolean;
  paymentTerm: number;
  contractCurrency: string;
  products: Product[];
  previousContractStartDate: string | null;
};

const convertImagePathToBase64 = (imagePath: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(imagePath)
      .then((response) => response.blob()) // Convert the response to a Blob
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); // Read the Blob as a Base64 string
        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          } else {
            reject(new Error("Failed to convert image to Base64."));
          }
        };
        reader.onerror = (error) => reject(error);
      })
      .catch((error) => reject(error));
  });
};

export async function generateContractPDF(
  contractDetails: ContractDetails
): Promise<void> {
  try {
    // Destructure contract details
    const {
      type,
      clientName,
      billingAddress,
      startDate,
      contractTerm,
      noticePeriod,
      autoRenewal,
      paymentTerm,
      contractCurrency,
      products,
      previousContractStartDate
    } = contractDetails;
    
    // Create a new jsPDF document
    const doc = new jsPDF();
    doc.setFont("helvetica", "bold");

    try {
      const base64String = await convertImagePathToBase64(logo);

      doc.addImage(base64String, "PNG", 85, 5, 34, 13);
    } catch (error) {
      console.error("Error loading image:", error);
    }

    // Define maximum Y position threshold and initial Y position
    const maxY = 275;
    let y = 40;

    // Function to add a new page if needed
    const checkAndAddPage = () => {
      if (y > maxY) {
        doc.addPage();
        y = 20; // Reset Y position after adding a new page
      }
    };

    // Function to add multiline text and adjust y position accordingly
    const addMultilineText = (
      text: string,
      x: number,
      maxWidth: number,
      lineSpacing: number = 5
    ) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line: string | string[]) => {
        checkAndAddPage();
        doc.text(line, x, y);
        y += lineSpacing;
      });
    };

    // Function to add a section and prevent breaking across pages
    const addSection = (
      header: string,
      content: { paragraph: string; description: string }[]
    ) => {
      const sectionHeight = content.reduce(
        (acc, { paragraph, description }) => {
          const paragraphHeight = 7;
          const descriptionHeight =
            doc.splitTextToSize(description, 190).length * 7;
          return acc + paragraphHeight + descriptionHeight + 5;
        },
        10
      );

      // Check if there is enough space for the entire section, if not, add a new page
      if (y + sectionHeight > maxY) {
        checkAndAddPage();
      }

      doc
        .setFontSize(11)
        .setFont("helvetica", "bold")
        .setTextColor(105, 105, 105);
      doc.text(header, 10, y);
      y += 10;

      content.forEach(({ paragraph, description }) => {
        checkAndAddPage();
        doc.setFontSize(10).setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text(paragraph, 10, y);
        doc
          .setFontSize(10)
          .setFont("helvetica", "normal")
          .setTextColor(0, 0, 0);
        y += 7;
        addMultilineText(description, 10, 190, 7);
        y += 5;
      });
    };

    if (type === "New Business") {
      // Add the title to the document
      doc.setFontSize(18);
      doc.text("Master Services Agreement (MSA)", 110, 25, { align: "center" });

      const addProducts = (products: Product[]) => {
        const licenseProduct = products.find(
          (product) => product.productType === "License"
        );
        const minimumUsers = licenseProduct ? licenseProduct.quantity : 0;

        const recurringFees = products.filter(
          (product) =>
            product.productType === "License" ||
            product.productType === "Module"
        );
        const nonRecurringFees = products.filter(
          (product) => product.productType === "Professional Services"
        );

        const pricePerUser =
          recurringFees.reduce(
            (total, product) => total + product.salesPrice,
            0
          ) * 12;

        const overagePricePerUser = recurringFees.reduce(
          (total, product) => total + product.overagePrice,
          0
        );

        const totalLicenseFee = pricePerUser * minimumUsers;

        const sectionHeight =
          (recurringFees.length + nonRecurringFees.length) * 10 + 50;

        if (y + sectionHeight > maxY) {
          checkAndAddPage();
        }

        doc
          .setFontSize(11)
          .setFont("helvetica", "bold")
          .setTextColor(105, 105, 105);
        doc.text("FEES", 10, y);
        y += 10;

        // Recurring Fees Summary
        doc.setFontSize(11).setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Yearly Recurring Fees:", 10, y);
        y += 7;

        doc.setFontSize(10).setFont("helvetica", "bold");
        doc.text("Minimum Amount of Users", 10, y);
        doc.text("PPU", 80, y);
        doc.text("APPU (monthly)", 120, y);
        y += 7;

        doc.setFont("helvetica", "normal");
        doc.text(
          formatNumberWithThousandsSeparator(minimumUsers, "users"),
          10,
          y
        );
        doc.text(
          formatNumberWithThousandsSeparator(pricePerUser, contractCurrency),
          80,
          y
        );
        doc.text(
          formatNumberWithThousandsSeparator(
            overagePricePerUser,
            contractCurrency
          ),
          120,
          y
        );
        y += 10;

        // List of Recurring Products
        doc.setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Included Products:", 10, y);
        y += 7;

        doc.setFont("helvetica", "normal");
        recurringFees.forEach((product) => {
          doc.text(`${product.productName} - ${product.description}`, 10, y, {
            maxWidth: 190,
          });
          y += 7;
        });

        y += 5;

        // Total License Fee
        doc.setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Total Yearly License Fee:", 10, y);
        doc.text(
          formatNumberWithThousandsSeparator(totalLicenseFee, contractCurrency),
          80,
          y
        );
        y += 10;

        // Non-Recurring Fees Table
        doc.setFontSize(11).setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Professional Services Fees:", 10, y);
        y += 7;

        doc.setFontSize(10).setFont("helvetica", "bold");
        doc.text("Name", 10, y);
        doc.text("Quantity", 80, y);
        doc.text("Sales Price", 120, y);
        doc.text("Total Price", 160, y);
        y += 7;

        doc.setFont("helvetica", "normal");
        nonRecurringFees.forEach((product) => {
          const totalPrice = product.salesPrice * product.quantity;
          doc.text(product.productName, 10, y);
          doc.text(
            formatNumberWithThousandsSeparator(
              product.quantity,
              product.quantityUnitOfMeasure
            ),
            80,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(
              product.salesPrice,
              contractCurrency
            ),
            120,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(totalPrice, contractCurrency),
            160,
            y
          );
          y += 7;
        });

        y += 10;
      };

      // Add contract parties
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      addMultilineText(
        `This Master Services Agreement ("Agreement") is entered into as of ${startDate} ("Effective Date") by and between:`,
        10,
        190,
        7
      );

      // Align Provider and Customer details
      const providerDetails = [
        "Intelly Solutions AB",
        "Sjöändavägen 20",
        "746 51 Bålsta",
        "Sweden",
      ];
      const clientDetails = [
        clientName,
        billingAddress.line1,
        billingAddress.line2 || "",
        `${billingAddress.postal_code} ${billingAddress.city}${
          billingAddress.state ? `, ${billingAddress.state}` : ""
        }`, // Replace with actual postal code and city
        billingAddress.country, // Replace with actual country
      ];

      y = 50; // Reset Y position for client details
      doc.setFont("helvetica", "bold");
      doc.text("Provider:", 10, y);
      doc.setFont("helvetica", "normal");
      y += 7;
      providerDetails.forEach((detail) => {
        if (detail && detail !== "") {
          checkAndAddPage();
          doc.text(detail, 10, y);
          y += 7;
        }
      });

      y = 50; // Reset Y position for client details
      doc.setFont("helvetica", "bold");
      doc.text("Customer:", 120, y);
      doc.setFont("helvetica", "normal");
      y += 7;
      clientDetails.forEach((detail) => {
        if (detail && detail !== "") {
          checkAndAddPage();
          doc.text(detail, 120, y);
          y += 7;
        }
      });

      // DEFINITIONS SECTION
      y = 90;

      addSection("1. DEFINITIONS", [
        {
          paragraph: "1.1 Agreement",
          description:
            '"Agreement" means this Master Services Agreement, including all attached exhibits and addenda.',
        },
        {
          paragraph: "1.2 Effective Date",
          description:
            '"Effective Date" means the date of execution of this Agreement.',
        },
        {
          paragraph: "1.3 Platform",
          description:
            '"Platform" means the unique dashboard and gamification platform provided by Provider as described in this Agreement.',
        },
        {
          paragraph: "1.4 Term",
          description:
            '"Term" means the period during which this Agreement remains in effect, as defined in Section 4.',
        },
        {
          paragraph: "1.5 Confidential Information",
          description:
            '"Confidential Information" means any information disclosed by either Party to the other that is either identified as confidential or that reasonably should be understood to be confidential given the nature of the information.',
        },
        {
          paragraph: "1.6 Price per User (PPU)",
          description:
            '"Price per User (PPU)" means the yearly price charged for each user, as set forth in the recurring fees section of the Agreement.',
        },
        {
          paragraph: "1.7 Additional Price per User (APPU)",
          description:
            '"Additional Price per User (APPU)" refers to the monthly additional price charged for each user exceeding the committed amount of users, as set forth in the Agreement.',
        },
      ]);

      addSection("2. SERVICES AND LICENSE GRANT", [
        {
          paragraph: "2.1 Scope",
          description:
            "Provider agrees to provide Customer access to the Platform, including its dashboard and gamification features, for the purpose of enhancing customer service operations.",
        },
        {
          paragraph: "2.2 License Grant",
          description:
            "Provider grants Customer a non-exclusive, non-transferable, revocable license to access and use the Platform during the Term in accordance with the terms and conditions set forth herein.",
        },
        {
          paragraph: "2.3 Service Levels",
          description:
            "Provider will use commercially reasonable efforts to make the Platform available 97.5% of the time, excluding scheduled maintenance and Force Majeure events.",
        },
        {
          paragraph: "2.4 Support Services",
          description:
            "Provider shall provide standard support services for the Platform as described in Exhibit C.",
        },
        {
          paragraph: "2.5 Data Location",
          description:
            "Provider confirms that all data processing and storage will be carried out in Stockholm, Sweden, in compliance with applicable data protection regulations.",
        },
      ]);

      addSection("3. FEES AND PAYMENT", [
        {
          paragraph: "3.1 Fees",
          description:
            "Customer agrees to pay Provider the fees as specified in Exhibit A attached hereto, in accordance with the terms outlined therein.",
        },
        {
          paragraph: "3.2 Payment Terms",
          description: `Customer shall make payments within ${paymentTerm} days of the invoice date. Late payments may incur interest at a rate of 4% per month or the maximum rate allowed by law, whichever is lower.`,
        },
        {
          paragraph: "3.3 Taxes",
          description:
            "Provider will add VAT at 25% for Swedish clients and European clients who have not provided a valid VAT identification number. All other clients are responsible for any applicable taxes, duties, or other charges related to the services provided by Provider.",
        },
        {
          paragraph: "3.4 Price Adjustments",
          description:
            "Provider reserves the right to adjust the fees annually, upon 60 days' prior written notice to Customer.",
        },
      ]);

      addSection("4. TERM AND TERMINATION", [
        {
          paragraph: "4.1 Term",
          description: `The initial term of this Agreement shall commence on the Effective Date and continue for ${contractTerm} months. Thereafter, this Agreement shall automatically renew for successive 12-month periods, unless either Party provides written notice of non-renewal at least ${noticePeriod} days before the end of the current term.`,
        },
        {
          paragraph: "4.2 Auto Renewal",
          description:
            "If auto renewal is enabled, the Agreement will automatically renew unless a written notice of termination is provided by either Party. The auto renewal will apply under the same terms and conditions as the initial term.",
        },
        {
          paragraph: "4.3 Termination for Cause",
          description:
            "Either Party may terminate this Agreement for cause, including material breach, by providing written notice to the other Party. Upon termination, Customer's access to the Platform will be immediately suspended.",
        },
        {
          paragraph: "4.4 Effect of Termination",
          description:
            "Upon termination or expiration of this Agreement, Customer shall immediately cease all use of the Platform and destroy all copies of any documentation provided by Provider.",
        },
        {
          paragraph: "4.5 Data Transfer Upon Termination",
          description:
            "Upon termination or expiration of this Agreement, Provider will facilitate the transfer of Customer's data back to Customer, or a third party designated by Customer, within 30 days. Provider shall permanently delete Customer's data from its systems within 60 days of termination unless required by law to retain it.",
        },
      ]);

      addSection("5. DATA PROCESSING ADDENDUM (DPA)", [
        {
          paragraph: "5.1 Incorporation",
          description:
            'The Data Processing Addendum ("DPA") attached as Exhibit B is incorporated into and forms an integral part of this Agreement.',
        },
        {
          paragraph: "5.2 Data Ownership",
          description:
            "Customer shall own all rights, title, and interest in and to all data provided by Customer to Provider. Provider shall act only as a processor of such data and shall not use it for any purpose other than to fulfill its obligations under this Agreement.",
        },
        {
          paragraph: "5.3 Data Protection and Security",
          description:
            "Provider shall implement appropriate technical and organizational measures to ensure the availability, authenticity, integrity, and confidentiality of Customer data in compliance with applicable data protection laws, including GDPR.",
        },
        {
          paragraph: "5.4 Data Access and Return",
          description:
            "Upon termination or expiration of this Agreement, Provider shall ensure the secure return or deletion of Customer data. Customer shall have the right to request the return of all data in a machine-readable format within 30 days of termination.",
        },
        {
          paragraph: "5.5 Data Breach Notification",
          description:
            "In the event of a data breach that compromises Customer data, Provider shall notify Customer within 24 hours of discovering the incident and provide regular updates until the breach is resolved.",
        },
      ]);

      addSection("6. WARRANTIES AND DISCLAIMERS", [
        {
          paragraph: "6.1 Warranty",
          description:
            "Provider warrants that the Platform will operate in a material manner consistent with its documentation. Customer's exclusive remedy for breach of this warranty is as provided in Section 7.",
        },
        {
          paragraph: "6.2 Disclaimer",
          description:
            'Except as expressly provided herein, the Platform is provided "as is" without warranty of any kind, whether express or implied, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, or non-infringement.',
        },
        {
          paragraph: "6.3 Uptime Warranty",
          description:
            "Provider warrants that the Platform will meet the uptime commitment specified in Section 2.3.",
        },
        {
          paragraph: "6.4 Service Level Agreement (SLA)",
          description:
            "Provider warrants that the Platform will maintain an uptime of 97.5% as detailed in Section 2.3.",
        },
        {
          paragraph: "6.5 Incident Handling",
          description:
            "Provider shall maintain an incident management process and cooperate fully with Customer in handling security incidents, including data breaches, downtime, or system failures. Provider will provide incident reports within 48 hours of the incident resolution.",
        },
      ]);

      addSection("7. LIMITATION OF LIABILITY", [
        {
          paragraph: "7.1 Limitation",
          description:
            "To the fullest extent permitted by law, Provider shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits, revenue, data, or business opportunities.",
        },
        {
          paragraph: "7.2 Total Liability",
          description:
            "Provider's total liability arising out of or in connection with this Agreement shall not exceed the total amount paid by Customer to Provider during the last twelve (12) months immediately preceding the event giving rise to such liability.",
        },
      ]);

      addSection("8. GOVERNING LAW AND JURISDICTION", [
        {
          paragraph: "8.1 Governing Law",
          description:
            "This Agreement shall be governed by and construed in accordance with the laws of Sweden.",
        },
        {
          paragraph: "8.2 Jurisdiction",
          description:
            "Any legal actions arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts located in Stockholm, Sweden.",
        },
      ]);

      addSection("9. DISPUTE RESOLUTION", [
        {
          paragraph: "9.1 Mediation",
          description:
            "In the event of a dispute arising out of or in connection with this Agreement, the Parties shall first attempt to resolve the dispute through good faith negotiations or mediation.",
        },
        {
          paragraph: "9.2 Arbitration",
          description:
            "If the dispute cannot be resolved through mediation, the Parties agree to submit the dispute to binding arbitration in Stockholm, Sweden, in accordance with the rules of the Arbitration Institute of the Stockholm Chamber of Commerce.",
        },
      ]);

      addSection("10. FORCE MAJEURE", [
        {
          paragraph: "10.1 Force Majeure Event",
          description:
            "Neither Party shall be liable for any failure or delay in performing its obligations under this Agreement if such failure or delay is due to a force majeure event, including but not limited to natural disasters, war, terrorism, strikes, or other events beyond the reasonable control of the affected Party.",
        },
      ]);

      addSection("11. CONFIDENTIALITY", [
        {
          paragraph: "11.1 Confidential Information",
          description:
            "Each Party agrees to maintain the confidentiality of any proprietary or confidential information disclosed by the other Party during the Term of this Agreement and not to disclose such information to any third party without the prior written consent of the disclosing Party.",
        },
        {
          paragraph: "11.2 Exclusions",
          description:
            "Confidential Information does not include information that is (a) publicly known, (b) already known to the receiving Party, (c) disclosed to the receiving Party by a third party without restriction, or (d) independently developed by the receiving Party.",
        },
      ]);

      addSection("12. INDEMNIFICATION", [
        {
          paragraph: "12.1 Indemnification by Customer",
          description:
            "Customer agrees to indemnify, defend, and hold harmless Provider from and against any and all claims, liabilities, damages, and expenses arising out of or related to Customer's use of the Platform, except to the extent caused by Provider's gross negligence or willful misconduct.",
        },
        {
          paragraph: "12.2 Indemnification by Provider",
          description:
            "Provider agrees to indemnify, defend, and hold harmless Customer from and against any and all claims, liabilities, damages, and expenses arising out of or related to any third-party claim that the Platform infringes any intellectual property rights of a third party, except to the extent caused by Customer's misuse of the Platform.",
        },
        {
          paragraph: "12.3 Cooperation with Authorities",
          description:
            "Provider agrees to cooperate with any government authorities as required by law in the context of data protection, security, or legal investigations, provided such cooperation does not conflict with Customer's data confidentiality obligations.",
        },
      ]);

      addSection("13. MISCELLANEOUS", [
        {
          paragraph: "13.1 Entire Agreement",
          description:
            "This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether oral or written.",
        },
        {
          paragraph: "13.2 Assignment",
          description:
            "Neither Party may assign its rights or obligations under this Agreement without the prior written consent of the other Party, except that Provider may assign this Agreement in connection with a merger, acquisition, or sale of all or substantially all of its assets.",
        },
        {
          paragraph: "13.3 Amendments",
          description:
            "Any amendments to this Agreement must be made in writing and signed by both Parties.",
        },
        {
          paragraph: "13.4 Severability",
          description:
            "If any provision of this Agreement is found to be invalid or unenforceable, the remainder of this Agreement shall remain in full force and effect.",
        },
        {
          paragraph: "13.5 Notices",
          description:
            "All notices under this Agreement shall be in writing and shall be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or upon receipt, if sent by certified or registered mail, return receipt requested.",
        },
      ]);

      // EXHIBIT A

      doc.addPage();
      y = 20; // Reset Y position after adding a new page

      doc.setFont("helvetica", "bold");

      // Add the title to the document
      doc.setFontSize(20);
      doc.text("EXHIBIT A: FEES AND PAYMENT TERMS", 105, 20, {
        align: "center",
      });
      y += 20;

      addProducts(products);

      addSection("PAYMENT TERMS", [
        {
          paragraph: "Overage Fees",
          description:
            "Customer will be invoiced for the full yearly fee initially, based on the agreed minimum number of users. Any additional users beyond the initial commitment will be charged an overage fee based on the highest number of active users during the previous month. These overage fees will be billed monthly in arrears at the overage price specified in the product details, and shall be payable upon receipt of the applicable invoice.",
        },
        {
          paragraph: "Payment Schedule",
          description:
            "Payment shall be made in advance according to the following schedule: Annually for recurring fees and upon invoice for one-off fees. The invoice will be issued upon platform start date. Overage fees will be billed monthly in arrears, based on the highest number of active users for the previous month.",
        },
        {
          paragraph: "Late Payments",
          description:
            "In the event that Customer fails to make payment as specified in the Payment Schedule, Provider reserves the right to assess late fees or take appropriate action as outlined in the Agreement.",
        },
        {
          paragraph: "Payment Method",
          description:
            "Customer shall make payments using the following method: Bank transfer as stated in the invoice they will receive.",
        },
        {
          paragraph: "Currency",
          description: `All fees shall be paid in ${contractCurrency}`,
        },
        {
          paragraph: "Taxes",
          description:
            "Customer is responsible for any applicable taxes, duties, or other charges related to the services provided by Provider as put forth in section 3.3 of this Agreement.",
        },
        {
          paragraph: "Changes to Fees",
          description:
            "Provider reserves the right to adjust fees under the following conditions: (a) annual inflation or cost of living adjustments, (b) changes in the scope or nature of the services provided, (c) significant changes in applicable regulations or compliance requirements, (d) increases in third-party licensing, hosting, or operational costs, or (e) major upgrades or enhancements to the Platform's functionality.",
        },
        {
          paragraph: "Refunds",
          description:
            "Refunds, if applicable, shall be governed by the terms outlined in the Agreement.",
        },
      ]);

      doc.addPage();
      y = 20; // Reset Y position after adding a new page

      doc.setFont("helvetica", "bold");

      // Add the title to the document
      doc.setFontSize(20);
      doc.text("EXHIBIT B - DATA PROCESSING ADDENDUM (DPA)", 105, 20, {
        align: "center",
      });

      addSection("", [
        {
          paragraph: "1. PURPOSE AND SCOPE",
          description:
            'This Data Processing Addendum ("DPA") forms an integral part of the Agreement between Provider and Customer, governing the processing of personal data by Provider on behalf of Customer. The purpose of this DPA is to ensure compliance with applicable data protection laws, including GDPR and relevant U.S. regulations such as CCPA.',
        },
        {
          paragraph: "2. DEFINITIONS",
          description:
            'Terms used in this DPA shall have the meanings set forth in the Agreement. Additionally, the following terms shall apply:\n- "Data Protection Laws": GDPR, CCPA, and other relevant data protection and privacy legislation.\n- "Data Subject": The individual to whom personal data relates.\n- "Personal Data": Any information relating to an identified or identifiable natural person processed by Provider on behalf of Customer.',
        },
        {
          paragraph: "3. ROLES AND RESPONSIBILITIES",
          description:
            "Customer is the Data Controller, and Provider is the Data Processor with respect to Personal Data processed under the Agreement. Provider shall only process Personal Data on documented instructions from Customer, except where required by applicable law.",
        },
        {
          paragraph: "4. DATA PROCESSING INSTRUCTIONS",
          description:
            "Provider shall process Personal Data strictly in accordance with Customer's instructions as documented in this Agreement and the relevant orders. Provider shall notify Customer immediately if it believes that an instruction infringes any Data Protection Laws.",
        },
        {
          paragraph: "5. DATA TYPES AND PROCESSING ACTIVITIES",
          description:
            "Provider may process the following types of Personal Data: \n- Contact information (e.g., name, email address).\n- Performance and operational metrics.\n- Interaction data collected via the Platform.\nProcessing activities include data collection, storage, aggregation, analysis, and deletion in order to provide the services as set out in the Agreement.",
        },
        {
          paragraph: "6. DATA LOCATION AND TRANSFERS",
          description:
            "All Personal Data shall be processed and stored in Stockholm, Sweden. If a transfer outside the European Economic Area (EEA) is required, Provider shall ensure such transfers comply with GDPR by implementing appropriate safeguards, including Standard Contractual Clauses (SCCs).",
        },
        {
          paragraph: "7. SECURITY MEASURES",
          description:
            "Provider shall implement appropriate technical and organizational measures to protect Personal Data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access. Such measures shall include:\n- Data encryption both in transit and at rest.\n- Access controls based on least privilege.\n- Regular security audits and vulnerability assessments.\n- Employee training on data protection.",
        },
        {
          paragraph: "8. SUB-PROCESSORS",
          description:
            "Provider may engage sub-processors to process Personal Data. A list of current sub-processors shall be made available to Customer upon request. Provider shall enter into a written agreement with each sub-processor ensuring that they meet obligations equivalent to those set out in this DPA. Customer shall be notified of any intended changes concerning the addition or replacement of sub-processors.",
        },
        {
          paragraph: "9. DATA SUBJECT RIGHTS",
          description:
            'Provider shall, to the extent permitted by law, assist Customer in fulfilling its obligations to respond to Data Subject requests to exercise rights under Data Protection Laws, such as access, rectification, erasure ("right to be forgotten"), restriction, and data portability.',
        },
        {
          paragraph: "10. PERSONAL DATA BREACH NOTIFICATION",
          description:
            "Provider shall notify Customer without undue delay and, where feasible, within 24 hours upon becoming aware of a Personal Data Breach. Provider shall provide sufficient information to assist Customer in meeting any obligations to notify affected individuals and relevant supervisory authorities.",
        },
        {
          paragraph: "11. DATA PROTECTION IMPACT ASSESSMENT (DPIA)",
          description:
            "Provider shall provide assistance to Customer, where necessary, for carrying out Data Protection Impact Assessments and prior consultations with supervisory authorities as required by GDPR.",
        },
        {
          paragraph: "12. AUDITS AND INSPECTIONS",
          description:
            "Provider shall allow Customer, or an independent auditor mandated by Customer, to conduct audits and inspections of Provider's data processing activities to verify compliance with this DPA and applicable Data Protection Laws. Such audits shall be conducted with reasonable notice, during regular business hours, and without disrupting Provider's operations.",
        },
        {
          paragraph: "13. DATA RETURN AND DELETION",
          description:
            "Upon termination or expiration of the Agreement, Provider shall, at Customer's choice, return all Personal Data in a machine-readable format or delete it, unless applicable law requires storage of the Personal Data. Provider shall confirm the deletion of Personal Data upon Customer's request.",
        },
        {
          paragraph: "14. LIABILITY",
          description:
            "The liability provisions of the Agreement shall apply to this DPA. Provider's liability arising from this DPA shall not exceed the limitations set forth in the Agreement, except to the extent required under applicable Data Protection Laws.",
        },
        {
          paragraph: "15. COOPERATION WITH AUTHORITIES",
          description:
            "Provider shall cooperate, upon request, with any competent supervisory authority in the performance of its tasks related to this DPA, provided such cooperation does not conflict with confidentiality obligations to Customer.",
        },
        {
          paragraph: "16. MISCELLANEOUS",
          description:
            "This DPA is subject to the terms of the Agreement, including its governing law and jurisdiction provisions. In case of any conflict between the terms of this DPA and the Agreement, the terms of this DPA shall prevail with respect to data protection and privacy matters.",
        },
      ]);

      doc.addPage();
      y = 20; // Reset Y position after adding a new page

      doc.setFont("helvetica", "bold");

      // Add the title to the document
      doc.setFontSize(20);
      doc.text("EXHIBIT C - SUPPORT SERVICES", 105, 20, { align: "center" });

      addSection("", [
        {
          paragraph: "1. PURPOSE AND SCOPE",
          description:
            "This Exhibit outlines the standard support services provided by Provider to ensure the effective operation and availability of the Platform. Provider agrees to provide technical support to Customer as described in this Exhibit C.",
        },
        {
          paragraph: "2. SUPPORT HOURS",
          description:
            "Provider shall provide support services during the following hours: 9:00 AM to 5:00 PM CET, Monday to Friday, excluding Swedish public holidays.",
        },
        {
          paragraph: "3. SUPPORT CHANNELS",
          description:
            "Customer may access support services via the following channels:\n- Help Section: Technical documentation, FAQs.\n- Email: Support can be accessed via email at support@intelly.solutions.",
        },
        {
          paragraph: "4. RESPONSE TIMES",
          description:
            "Provider shall respond to support requests based on the severity of the issue, as follows:\n- Critical Issues Acknowledgment within 1 business day. A Critical Issue is defined as an issue that results in complete unavailability of the Platform or a critical malfunction severely impacting multiple end users.\n- Major Issues: Acknowledgment within 2 business days. A Major Issue is defined as an issue causing significant degradation of functionality or performance for multiple end users but does not result in complete unavailability.\n- Minor Issues: Acknowledgment within 5 business days. A Minor Issue is defined as an issue that causes minor impact on functionality or performance, affecting a limited number of end users.",
        },
        {
          paragraph: "5. ESCALATION PROCESS",
          description:
            "If a reported issue is not acknowledged within the specified response time, or if the resolution time exceeds reasonable expectations, Customer may escalate the issue by contacting their designated commercial contact.",
        },
        {
          paragraph: "6. RESOLUTION PROCESS",
          description:
            "- Initial Assessment: Upon receipt of a support request, Provider will conduct an initial assessment to determine the severity and scope of the issue.\n- Communication: Provider will provide regular updates to Customer regarding the status of the issue and any steps being taken to resolve it.\n- Resolution: Provider will use commercially reasonable efforts to resolve the issue in a timely manner. Provider reserves the right to determine whether a reported issue is a bug and the appropriate course of action for resolution.",
        },
        {
          paragraph: "7. MAINTENANCE WINDOWS",
          description:
            "Provider may schedule routine maintenance windows for the Platform. Provider will provide Customer with at least seventy-two (72) hours' notice of any scheduled maintenance that may impact the availability or performance of the Platform. Provider strives to schedule maintenance during periods that will have the least impact on end users. Emergency maintenance may be conducted with less notice in the event of critical security vulnerabilities or other urgent issues.",
        },
        {
          paragraph: "8. LIMITATIONS",
          description:
            "This SLA does not guarantee resolution times. Provider will use reasonable efforts to address reported issues in accordance with the procedures outlined above.",
        },
        {
          paragraph: "9. EXCLUSIONS",
          description:
            "This SLA does not apply to issues caused by:\n- (a) Misuse of the Platform by Customer or end users.\n- (b) Modifications to the Platform not authorized by Provider.\n- (c) Customer's failure to maintain the necessary infrastructure and environment as required for the Platform.\n- (d) Any force majeure event as defined in this Agreement.",
        },
      ]);

      // Save the generated PDF and prompt a download in the browser
      doc.save(`MSA_${clientName}_${startDate.replaceAll("-", "")}.pdf`);
    }

    if (type === "Up-sell") {
      const addProducts = (products: Product[]) => {
        const recurringFees = products.filter(
          (product) => product.revenueType === "Recurring"
        );
        const nonRecurringFees = products.filter(
          (product) => product.revenueType === "Non-recurring"
        );

        const sectionHeight =
          (recurringFees.length + nonRecurringFees.length) * 10 + 50;

        if (y + sectionHeight > maxY) {
          checkAndAddPage();
        }

        doc
          .setFontSize(11)
          .setFont("helvetica", "bold")
          .setTextColor(105, 105, 105);
        doc.text("FEES", 10, y);
        y += 10;

        // Recurring Fees Summary
        doc.setFontSize(11).setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Recurring Fees:", 10, y);
        y += 7;

        doc.setFontSize(10).setFont("helvetica", "bold");
        doc.text("Product", 10, y);
        doc.text("Users", 80, y);
        doc.text("PPU", 110, y);
        doc.text("APPU (monthly)", 140, y);
        doc.text("Change", 170, y); // Added Change column
        y += 7;

        doc.setFont("helvetica", "normal");
        recurringFees.forEach((product) => {
          doc.setFont("helvetica", "normal");
          doc.text(product.productName, 10, y);
          doc.text(
            formatNumberWithThousandsSeparator(product.quantity, "users"),
            80,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(
              product.salesPrice * 12,
              contractCurrency
            ),
            110,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(
              product.overagePrice,
              contractCurrency
            ),
            140,
            y
          );
          doc.text(
            product.change || "", // This would be dynamic based on your product's change value
            170,
            y
          );
          y += 10;
        });

        // List of Recurring Products
        doc.setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Included Products:", 10, y);
        y += 7;

        y += 5;

        // Non-Recurring Fees Table
        doc.setFontSize(11).setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Professional Services Fees:", 10, y);
        y += 7;

        doc.setFontSize(10).setFont("helvetica", "bold");
        doc.text("Name", 10, y);
        doc.text("Quantity", 80, y);
        doc.text("Sales Price", 100, y);
        doc.text("Total Price", 130, y);
        doc.text("Change", 150, y); // Added Change column for Non-Recurring Fees
        y += 7;

        doc.setFont("helvetica", "normal");
        nonRecurringFees.forEach((product) => {
          const totalPrice = product.salesPrice * product.quantity;
          doc.text(product.productName, 10, y);
          doc.text(
            formatNumberWithThousandsSeparator(
              product.quantity,
              product.quantityUnitOfMeasure
            ),
            80,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(
              product.salesPrice,
              contractCurrency
            ),
            100,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(totalPrice, contractCurrency),
            130,
            y
          );
          doc.text(
            product.change || "", // Dynamic value for Change ("Added" or "Updated")
            150,
            y
          );
          y += 7;
        });

        y += 10;
      };

      // Add the title to the document
      doc.setFontSize(20);
      doc.text("AMENDMENT AGREEMENT", 105, 30, { align: "center" });

      // Add contract parties
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      addMultilineText(
        `This Amendment Agreement ("Agreement") is entered into as of ${startDate} ("Effective Date") by and between:`,
        10,
        190,
        7
      );

      // Align Provider and Customer details
      const providerDetails = [
        "Intelly Solutions AB",
        "Sjöändavägen 20",
        "746 51 Bålsta",
        "Sweden",
      ];
      const clientDetails = [
        clientName,
        billingAddress.line1,
        billingAddress.line2 || "",
        `${billingAddress.postal_code} ${billingAddress.city}${
          billingAddress.state ? `, ${billingAddress.state}` : ""
        }`, // Replace with actual postal code and city
        billingAddress.country, // Replace with actual country
      ];

      y = 50; // Reset Y position for client details
      doc.setFont("helvetica", "bold");
      doc.text("Provider:", 10, y);
      doc.setFont("helvetica", "normal");
      y += 7;
      providerDetails.forEach((detail) => {
        if (detail && detail !== "") {
          checkAndAddPage();
          doc.text(detail, 10, y);
          y += 7;
        }
      });

      y = 50; // Reset Y position for client details
      doc.setFont("helvetica", "bold");
      doc.text("Customer:", 120, y);
      doc.setFont("helvetica", "normal");
      y += 7;
      clientDetails.forEach((detail) => {
        if (detail && detail !== "") {
          checkAndAddPage();
          doc.text(detail, 120, y);
          y += 7;
        }
      });

      // DEFINITIONS SECTION
      y = 90;

      addSection("1. DEFINITIONS", [
        {
          paragraph: "1.1 Effective Date",
          description:
            '"Effective Date" means the date this Amendment takes effect.',
        },
        {
          paragraph: "1.2 Amended Products and Services",
          description:
            '"Amended Products and Services" refers to the additional products, services, or licenses as described in Appendix A, which are added to the Agreement by this Amendment.',
        },
        {
          paragraph: "1.3 Price per User (PPU)",
          description:
            '"Price per User (PPU)" means the yearly price charged for each user, as set forth in the recurring fees section of the Agreement.',
        },
        {
          paragraph: "1.4 Additional Price per User (APPU)",
          description:
            '"Additional Price per User (APPU)" refers to the monthly additional price charged for each user exceeding the committed amount of users, as set forth in the Agreement.',
        },
        {
          paragraph: "1.5 Original Agreement",
          description:
            `"Original Agreement" means the Master Services Agreement ("MSA") executed between the Provider and the Customer, originally dated ${previousContractStartDate}, to which this Amendment Agreement is subject.`,
        },
      ]);

      addSection("2. AMENDMENT TERMS", [
        {
          paragraph: "2.1 Additional Products and Services",
          description:
            "The Provider agrees to deliver, and the Customer agrees to pay for, the additional products and services described in Appendix A. These products and services shall be governed by the same terms and conditions as set forth in the original Agreement, unless otherwise specified.",
        },
        {
          paragraph: "2.2 Amendments to Pricing",
          description:
            "The pricing for the additional products and services will be as outlined in Appendix A. All other pricing terms from the Agreement remain unchanged. For any increase in the number of users for the Intelly Platform product, the Customer shall only be invoiced for the additional users at the new rate. No additional fees will be charged for users already covered under the original Agreement. Other products or services such as additional modules or professional services will follow the pricing outlined in Appendix A.",
        },
        {
          paragraph: "2.3 Integration with Original Agreement",
          description:
            "This Amendment is an integral part of the Agreement. In case of any inconsistencies between this Amendment and the Agreement, the terms of this Amendment shall prevail.",
        },
      ]);

      addSection("3. CUSTOMER OBLIGATIONS", [
        {
          paragraph: "3.1 Payment for Amended Products and Services",
          description:
            "The Customer shall pay for the amended products and services in accordance with the payment terms specified in the Agreement.",
        },
      ]);

      addSection("4. TERM AND TERMINATION", [
        {
          paragraph: "4.1 Amendment Term",
          description:
            "This Amendment shall remain in effect for the duration of the Agreement unless otherwise terminated in accordance with the terms of the Agreement.",
        },
      ]);

      doc.addPage();
      y = 20; // Reset Y position after adding a new page

      doc.setFont("helvetica", "bold");

      // Add the title to the document
      doc.setFontSize(20);
      doc.text("APPENDIX A - AMENDED PRODUCTS AND SERVICES", 105, 20, {
        align: "center",
      });

      y += 20;

      addProducts(products);

      doc.save(`Amendment_${clientName}_${startDate.replaceAll("-", "")}.pdf`);
    }

    if (type === "Renewal") {
      const addProducts = (products: Product[]) => {
        const licenseProduct = products.find(
          (product) => product.productType === "License"
        );
        const minimumUsers = licenseProduct ? licenseProduct.quantity : 0;

        const recurringFees = products.filter(
          (product) =>
            product.productType === "License" ||
            product.productType === "Module"
        );
        const nonRecurringFees = products.filter(
          (product) => product.productType === "Professional Services"
        );

        const pricePerUser =
          recurringFees.reduce(
            (total, product) => total + product.salesPrice,
            0
          ) * 12;

        const overagePricePerUser = recurringFees.reduce(
          (total, product) => total + product.overagePrice,
          0
        );

        const totalLicenseFee = pricePerUser * minimumUsers;

        const sectionHeight =
          (recurringFees.length + nonRecurringFees.length) * 10 + 50;

        if (y + sectionHeight > maxY) {
          checkAndAddPage();
        }

        doc
          .setFontSize(11)
          .setFont("helvetica", "bold")
          .setTextColor(105, 105, 105);
        doc.text("FEES", 10, y);
        y += 10;

        // Recurring Fees Summary
        doc.setFontSize(11).setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Yearly Recurring Fees:", 10, y);
        y += 7;

        doc.setFontSize(10).setFont("helvetica", "bold");
        doc.text("Minimum Amount of Users", 10, y);
        doc.text("PPU", 80, y);
        doc.text("APPU (monthly)", 120, y);
        y += 7;

        doc.setFont("helvetica", "normal");
        doc.text(
          formatNumberWithThousandsSeparator(minimumUsers, "users"),
          10,
          y
        );
        doc.text(
          formatNumberWithThousandsSeparator(pricePerUser, contractCurrency),
          80,
          y
        );
        doc.text(
          formatNumberWithThousandsSeparator(
            overagePricePerUser,
            contractCurrency
          ),
          120,
          y
        );
        y += 10;

        // List of Recurring Products
        doc.setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Included Products:", 10, y);
        y += 7;

        doc.setFont("helvetica", "normal");
        recurringFees.forEach((product) => {
          doc.text(`${product.productName} - ${product.description}`, 10, y, {
            maxWidth: 190,
          });
          y += 7;
        });

        y += 5;

        // Total License Fee
        doc.setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Total Yearly License Fee:", 10, y);
        doc.text(
          formatNumberWithThousandsSeparator(totalLicenseFee, contractCurrency),
          80,
          y
        );
        y += 10;

        // Non-Recurring Fees Table
        doc.setFontSize(11).setFont("helvetica", "bold").setTextColor(0, 0, 0);
        doc.text("Professional Services Fees:", 10, y);
        y += 7;

        doc.setFontSize(10).setFont("helvetica", "bold");
        doc.text("Name", 10, y);
        doc.text("Quantity", 80, y);
        doc.text("Sales Price", 120, y);
        doc.text("Total Price", 160, y);
        y += 7;

        doc.setFont("helvetica", "normal");
        nonRecurringFees.forEach((product) => {
          const totalPrice = product.salesPrice * product.quantity;
          doc.text(product.productName, 10, y);
          doc.text(
            formatNumberWithThousandsSeparator(
              product.quantity,
              product.quantityUnitOfMeasure
            ),
            80,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(
              product.salesPrice,
              contractCurrency
            ),
            120,
            y
          );
          doc.text(
            formatNumberWithThousandsSeparator(totalPrice, contractCurrency),
            160,
            y
          );
          y += 7;
        });

        y += 10;
      };

      doc.setFontSize(20);
      doc.text("RENEWAL AGREEMENT", 105, 30, { align: "center" });

      // Add contract parties
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      addMultilineText(
        `This Renewal Agreement ("Agreement") is entered into as of ${startDate} ("Effective Date") by and between:`,
        10,
        190,
        7
      );

      // Align Provider and Customer details
      const providerDetails = [
        "Intelly Solutions AB",
        "Sjöändavägen 20",
        "746 51 Bålsta",
        "Sweden",
      ];
      const clientDetails = [
        clientName,
        billingAddress.line1,
        billingAddress.line2 || "",
        `${billingAddress.postal_code} ${billingAddress.city}${
          billingAddress.state ? `, ${billingAddress.state}` : ""
        }`, // Replace with actual postal code and city
        billingAddress.country, // Replace with actual country
      ];

      y = 50; // Reset Y position for client details
      doc.setFont("helvetica", "bold");
      doc.text("Provider:", 10, y);
      doc.setFont("helvetica", "normal");
      y += 7;
      providerDetails.forEach((detail) => {
        if (detail && detail !== "") {
          checkAndAddPage();
          doc.text(detail, 10, y);
          y += 7;
        }
      });

      y = 50; // Reset Y position for client details
      doc.setFont("helvetica", "bold");
      doc.text("Customer:", 120, y);
      doc.setFont("helvetica", "normal");
      y += 7;
      clientDetails.forEach((detail) => {
        if (detail && detail !== "") {
          checkAndAddPage();
          doc.text(detail, 120, y);
          y += 7;
        }
      });

      // DEFINITIONS SECTION
      y = 90;

      addSection("1. DEFINITIONS", [
        {
          paragraph: "1.1 Effective Date",
          description:
            '"Effective Date" means the date this Renewal Agreement takes effect, as defined in Section 2.1.',
        },
        {
          paragraph: "1.2 Renewal Term",
          description:
            '"Renewal Term" refers to the extended period during which the services or products described in the Agreement shall continue to be provided, starting from the Effective Date of this Renewal Agreement and lasting for the duration specified in Section 2.2.',
        },
        {
          paragraph: "1.3 Updated Products and Services",
          description:
            '"Updated Products and Services" refers to the products and services listed in Appendix A, which shall replace or supplement the original products and services outlined in the Agreement.',
        },
        {
          paragraph: "1.4 Original Agreement",
          description:
            `"Original Agreement" means the Master Services Agreement ("MSA") executed between the Provider and the Customer, originally dated ${previousContractStartDate}, to which this Renewal Agreement is subject.`,
        },
        {
          paragraph: "1.5 Price per User (PPU)",
          description:
            '"Price per User (PPU)" means the recurring yearly price charged for each user, as specified in the recurring fees section of this Agreement or Appendix A.',
        },
        {
          paragraph: "1.6 Additional Price per User (APPU)",
          description:
            '"Additional Price per User (APPU)" refers to the additional monthly price charged for each user exceeding the committed number of users, as outlined in the Agreement or Appendix A.',
        }
      ]);      
      
      addSection("2. RENEWAL TERMS", [
        {
          paragraph: "2.1 Effective Date",
          description:
            `The Effective Date of this Renewal Agreement is ${startDate}, from which point the terms and conditions of this Renewal Agreement shall be in effect.`,
        },
        {
          paragraph: "2.2 Renewal Term Duration",
          description:
            `The Renewal Term shall last for a period of ${contractTerm} months, beginning on the Effective Date, unless otherwise terminated in accordance with the terms of the Original Agreement.`,
        },
        {
          paragraph: "2.3 Updated Products and Services",
          description:
            "The Provider agrees to deliver, and the Customer agrees to pay for, the updated products and services described in Appendix A. These products and services shall be governed by the same terms and conditions as set forth in the Original Agreement, unless otherwise specified in this Renewal Agreement.",
        },
        {
          paragraph: "2.4 Amendments to Pricing",
          description:
            "The pricing for the updated products and services will be as outlined in Appendix A. All other pricing terms from the Original Agreement remain unchanged. Additional users, modules, or services added during the Renewal Term will be invoiced according to the rates specified in Appendix A.",
        },
      ]);
      
      addSection("3. CUSTOMER OBLIGATIONS", [
        {
          paragraph: "3.1 Payment for Updated Products and Services",
          description:
            "The Customer shall make payments for the updated products and services in accordance with the payment terms specified in the Original Agreement, as modified by this Renewal Agreement.",
        },
        {
          paragraph: "3.2 Compliance with Original Terms",
          description:
            "The Customer agrees to continue to comply with all obligations set forth in the Original Agreement, including but not limited to the timely payment of fees and adherence to service-level agreements (SLAs).",
        },
      ]);
      
      addSection("4. TERM AND TERMINATION", [
        {
          paragraph: "4.1 Renewal Termination",
          description:
            'This Renewal Agreement shall remain in effect for the duration of the Renewal Term specified in Section 2.2. Either party may terminate this Renewal Agreement in accordance with the termination provisions of the Original Agreement.',
        },
        {
          paragraph: "4.2 Termination for Cause",
          description:
            "The Provider or Customer may terminate this Renewal Agreement for cause, including material breach, by providing written notice to the other party. Upon termination, the Customer's access to the updated products and services will be immediately suspended.",
        },
      ]);
      
      addSection("5. GENERAL TERMS", [
        {
          paragraph: "5.1 Integration with Original Agreement",
          description:
            'This Renewal Agreement is an extension of the Original Agreement and forms an integral part thereof. In case of any inconsistencies between this Renewal Agreement and the Original Agreement, the terms of this Renewal Agreement shall prevail.',
        },
        {
          paragraph: "5.2 Governing Law and Jurisdiction",
          description:
            'This Renewal Agreement shall be governed by the same governing law and jurisdiction clauses as stated in the Original Agreement.',
        },
      ]);


      doc.addPage();
      y = 20; // Reset Y position after adding a new page

      // Updated Products (Exhibit A)
      doc.setFont("helvetica", "bold").setTextColor(0, 0, 0);
      doc.text("APPENDIX A: UPDATED PRODUCTS AND FEES", 10, y);
      y += 10;

      // Adding the updated products list
      addProducts(products);

      doc.save(`Renewal_${clientName}_${startDate.replaceAll("-", "")}.pdf`);
    }
  } catch (err) {
    console.error(err);
  }
}
