import React, { useState, useEffect } from 'react'
import { fetchCollateral } from '../../app.service'
import { useAppContext } from '../../AppContext'
import { Input } from '../ui/input'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card'

interface ContentItem {
  id: string
  type: string
  title: string
  description: string
  previewImage: string
  downloadLink: string
}

const Collateral: React.FC = () => {
  const [content, setContent] = useState<ContentItem[]>([])
  const [activeTab, setActiveTab] = useState<string>('All')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const { currentToken } = useAppContext()

  useEffect(() => {
    const fetchContent = async () => {
      if (currentToken) {
        const result = await fetchCollateral(currentToken)
        if (result.success) {
          setContent(result.data)
        } else {
          console.error('Error fetching collateral:', result.message)
        }
      }
    }

    fetchContent()
  }, [currentToken])

  const contentTypes = [
    'All',
    ...Array.from(new Set(content.map((item) => item.type))),
  ]

  const filteredContent = content.filter((item) => {
    const matchesType = activeTab === 'All' || item.type === activeTab
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
    return matchesType && matchesSearch
  })

  // const handleDownload = async (downloadLink: string, title: string) => {
  //   try {
  //     const response = await fetch(downloadLink)
  //     const blob = await response.blob()
  //     const url = window.URL.createObjectURL(blob)
  //     const a = document.createElement('a')
  //     a.style.display = 'none'
  //     a.href = url
  //     a.download = title
  //     document.body.appendChild(a)
  //     a.click()
  //     window.URL.revokeObjectURL(url)
  //   } catch (error) {
  //     console.error('Download failed:', error)
  //   }
  // }

  // const handleDownload = async (filePath: string, title: string) => {
  //   try {
  //     const storageRef = firebase.storage().ref();
  //     const fileRef = storageRef.child(filePath);
  //     const downloadUrl = await fileRef.getDownloadURL();

  //     const response = await fetch(downloadUrl);
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.style.display = 'none';
  //     a.href = url;
  //     a.download = title;
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Download failed:', error);
  //   }
  // };

  return (
    <div className="flex flex-col h-screen">
      {/* Top Bar */}
      <div className="flex items-center px-4 h-20 bg-white border-b">
        {/* Heading */}
        <h1 className="text-2xl font-bold mr-12">Collateral</h1>

        {/* Search Bar */}
        <Input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          placeholder="Search"
          className="mr-12"
        />

        {/* Tabs */}
        <div className="flex h-full items-end">
          {contentTypes.map((type) => (
            <button
              key={type}
              onClick={() => setActiveTab(type)}
              className={`mr-4  px-4 h-full text-sm font-medium border-b-2 ${
                activeTab === type
                  ? 'border-primary text-primary'
                  : 'border-transparent'
              }`}
            >
              {type}
            </button>
          ))}
        </div>
      </div>

      {/* Collateral Content */}
      <div className="flex flex-col h-[90%] p-4 space-y-4 overflow-auto bg-slate-50">
        {filteredContent.length > 0 ? (
          <div className="grid grid-cols-3 gap-4">
            {filteredContent.map((item) => (
              <Card key={item.id} className="flex gap-2 overflow-clip h-52">
                <CardHeader className="w-3/5">
                  <CardTitle className="text-lg">{item.title}</CardTitle>
                  <CardDescription className="flex flex-col justify-between h-full">
                    <span>{item?.description}</span>
                    <a
                      // onClick={() =>
                      //   handleDownload(item.downloadLink, item.title)
                      // }
                      download
                      target="_blank"
                      rel="noreferrer"
                      href={item.downloadLink}
                      className="mt-4 text-xs flex gap-2 items-center border rounded-lg px-3 py-1 hover:bg-primary hover:text-white border-primary text-primary max-w-max"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                      Download
                    </a>
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-4 w-2/5 h-full flex items-center justify-center bg-slate-50">
                  <img
                    src={item.previewImage}
                    alt={item.title}
                    className="h-auto object-cover mb-2"
                  />
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <p>No content available</p>
        )}
      </div>
    </div>
  )
}

export default Collateral
