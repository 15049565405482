import axios from 'axios';
import { DateTime } from 'luxon';

const BASEURL = process.env.REACT_APP_API_URL;

export async function getServerHealth(): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/admin/server-health`, {
      timeout: 5000, // Timeout set to 5000 milliseconds (5 seconds)
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      if (error.code === 'ECONNABORTED') {
        // Handle timeout specifically
        return {
          success: false,
          message: 'Request timed out, server may be down',
        };
      }
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}
// Function to get the initial user by email
export async function getUserByEmail(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/user`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getDatedConversionRates(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/dated-conversion-rates`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to get partner customer data (original function)
export async function getCustomer(customerId: number, userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/customers/customer`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        customerId: encodeURIComponent(customerId),
      },
    });
    return response.data.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// New function to get customer details using path parameter and query parameter for partnerCustomerId
export async function fetchCustomerDetails(userToken: string, partnerCustomerId: number, customerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/customers/${customerId}`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        partnerCustomerId,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch opportunities (no customerId required)
export async function getOpportunities(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/opportunities`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getAccounts(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/accounts`, {
      headers: { Authorization: userToken }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function createAccount(
  data: {
    name: string,
    type: string,
    status: string,
    territory: string
  },
  userToken: string
): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/accounts`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function updateAccount(
  accountId: number,
  data: {
    name: string,
    type: string,
    status: string,
    territory: string,
    ownerId: number
  },
  userToken: string
): Promise<any> {
  try {
    const response = await axios.patch(`${BASEURL}/engage/accounts`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { accountId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getProducts(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/products`, {
      headers: { Authorization: userToken }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getAccount(userToken: string, accountId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/account`, {
      headers: { Authorization: userToken },
      params: { accountId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function queryAccount(userToken: string, name: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/account`, {
      headers: { Authorization: userToken },
      params: { name },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getContacts(userToken: string, accountId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/contacts`, {
      headers: { Authorization: userToken },
      params: { accountId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function fetchOpportunities(userToken: string, accountId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/opportunities`, {
      headers: { Authorization: userToken },
      params: { accountId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function fetchCommisionModel(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/commission-model`, {
      headers: { Authorization: userToken },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getUsers(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/users`, {
      headers: { Authorization: userToken },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to add a new opportunity (no customerId required)
export async function createOpportunity(
  data: {
    name: string;
    type: string;
    stage: string;
    nextSteps: string | null;
    currency: string;
    products: any;
    closeDate: string;
    annualRecurringRevenue: number | null;
    nonRecurringRevenue: number | null;
    previousAnnualRecurringRevenue: number | null;
    ownerId: number;
    accountId: number;
  },
  userToken: string,
): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/opportunities`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to update an opportunity (no customerId required)
export async function updateOpportunity(
  opportunityId: number,
  data: {
    stage: string,
    nextSteps: string,
    currency: string,
    products: any,
    closeDate: string,
    annualRecurringRevenue: number,
    previousAnnualRecurringRevenue: number,
    lossReason: string,
    lossReasonDescription: string,
    contractTerm: number
  },
  userToken: string
): Promise<any> {
  try {
    const response = await axios.patch(`${BASEURL}/engage/opportunities`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { opportunityId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to update an opportunity (no customerId required)
export async function deleteOpportunity(
  opportunityId: number,
  userToken: string
): Promise<any> {
  try {
    const response = await axios.delete(`${BASEURL}/engage/opportunities`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { opportunityId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to create a new contact
export async function createContact(
  data: {
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    email: string;
    accountId: number;
  },
  userToken: string,
): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/contacts`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to update an existing contact
export async function updateContact(
  contactId: number,
  data: {
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    email: string;
    accountId: number;
  },
  userToken: string
): Promise<any> {
  try {
    const response = await axios.patch(`${BASEURL}/engage/contacts`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { contactId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to delete an existing contact
export async function deleteContact(
  contactId: number,
  userToken: string
): Promise<any> {
  try {
    const response = await axios.delete(`${BASEURL}/engage/contacts`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { contactId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getLeads(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/leads`, {
      headers: { Authorization: userToken },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function qualifyLead(userToken: string, leadId: number): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/leads/qualify`, {}, {
      headers: { Authorization: userToken },
      params: { leadId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function disqualifyLead(userToken: string, leadId: number): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/leads/disqualify`, {}, {
      headers: { Authorization: userToken },
      params: { leadId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function revertLead(userToken: string, leadId: number): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/leads/revert`, {}, {
      headers: { Authorization: userToken },
      params: { leadId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getContracts(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/contracts`, {
      headers: { Authorization: userToken },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function terminateContract(userToken: string, contractId: number): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/contracts/terminate`, {}, {
      headers: { Authorization: userToken },
      params: { contractId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function cancelPendingTermination(userToken: string, contractId: number): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/contracts/cancel-termination`, {}, {
      headers: { Authorization: userToken },
      params: { contractId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to reset a user's password
export async function resetPassword(userToken: string, partnerCustomerId: number, userId: number): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/partner/reset-password`, {
      userId,
    }, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch collateral data
export async function fetchCollateral(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/collateral`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch collateral data
export async function fetchCountries(userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/countries`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function createTrialCustomer(
  data: {
    accountId: number;
    billingAddress: any;
    customerName: string;
    invoicingEmail: string;
    trialEnds: string;
  },
  userToken: string,
): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/trial-customer`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function prolongTrial(userToken: string, customerId: number, trialEnds: string): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/prolong-trial`, {}, {
      headers: { Authorization: userToken },
      params: { customerId, trialEnds },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function createNote(
  data: {
    subject: string;
    description: string;
    noteDate: string;
    type: string;
    accountId: number;
  },
  userToken: string,
): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/engage/notes`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function updateNote(
  data: {
    subject: string;
    description: string;
    noteDate: string;
    type: string;
    accountId: number;
  },
  noteId: number,
  userToken: string,
): Promise<any> {
  try {
    const response = await axios.patch(`${BASEURL}/engage/notes`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { noteId }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getNotes(userToken: string, accountId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/notes`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { accountId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getMarketingMessages(userToken: string, email: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/marketing-messages`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { email },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getDateRanges(userToken: string,): Promise<any> {
  try {
    
    const response = await axios.get(`${BASEURL}/engage/dateranges`, {
      headers: { Authorization: userToken },
    });
    
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

export async function getStatistics(userToken: string, period: string, timeZone: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/engage/statistics`, {
      headers: { Authorization: userToken },
      params: { period, timeZone }
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}


// export async function fetchActiveUsers(
//   userToken: string,
//   partnerCustomerId: number,
//   customerId: number
// ): Promise<any> {
//   try {
//     const response = await axios.get(`${BASEURL}/partner/customers/${customerId}/active-users`, {
//       headers: { Authorization: userToken },
//       params: { partnerCustomerId },
//     });
//     return response.data;
//   } catch (error: any) {
//     if (axios.isAxiosError(error)) {
//       const errorMessage = error.response?.data?.message || error.message;
//       return {
//         success: false,
//         message: errorMessage,
//       };
//     } else {
//       return {
//         success: false,
//         message: 'An unexpected error occurred',
//       };
//     }
//   }
// }

// export async function fetchCustomerStatistics(userToken: string, partnerCustomerId: number, customerId: number): Promise<any> {
//   try {
//     const response = await axios.get(`${BASEURL}/partner/customers/${customerId}/statistics`, {
//       headers: { Authorization: userToken },
//       params: { partnerCustomerId },
//     });
//     return response.data;
//   } catch (error: any) {
//     if (axios.isAxiosError(error)) {
//       const errorMessage = error.response?.data?.message || error.message;
//       return {
//         success: false,
//         message: errorMessage,
//       };
//     } else {
//       return {
//         success: false,
//         message: 'An unexpected error occurred',
//       };
//     }
//   }
// }