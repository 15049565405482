import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { getServerHealth } from './app.service';

// Define the shape of your context state
type AppContextType = {
  currentToken: string | null;
  setCurrentToken: (token: string | null) => void;
  currentUser: any;
  setCurrentUser: (user: any) => void;
  currentCustomer: any;
  setCurrentCustomer: (customer: any) => void;
  authorized: boolean;
  setAuthorized: (authorized: boolean) => void;
  isServerDown: boolean | null; // Change to `boolean | null`
  isMaintenanceMode: boolean;
  isLoadingServerStatus: boolean; // New: to track the server status loading state
  conversionRates: any;
  setConversionRates: (conversionRate: any) => void;
  countries: any;
  setCountries: (country: any) => void;
};

// Create the context
const AppContext = createContext<AppContextType | undefined>(undefined);

// Create a provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentToken, setCurrentToken] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentCustomer, setCurrentCustomer] = useState<any>(null);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [isServerDown, setIsServerDown] = useState<boolean | null>(null); // Set initially to `null`
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isLoadingServerStatus, setIsLoadingServerStatus] = useState(true); // New loading state

  const [conversionRates, setConversionRates] = useState<any>([])
  const [countries, setCountries] = useState<any>([]);

  useEffect(() => {
    // Logic to check server status and maintenance mode
    const checkServerStatus = async () => {
      try {
        const response = await getServerHealth();
        
        if (!response.success) {
          setIsServerDown(true);
        } else {
          setIsServerDown(false);
        }
      } catch (error) {
        setIsServerDown(true); // Assume server is down if fetch fails
      } finally {
        setIsLoadingServerStatus(false); // Finished checking the server status
      }
    };

    const checkMaintenanceMode = async () => {
      // Example logic to check maintenance mode
      const isMaintenance = false; // Replace with real check
      setIsMaintenanceMode(isMaintenance);
    };

    // Call both checks
    checkServerStatus();
    checkMaintenanceMode();
  }, []);

  return (
    <AppContext.Provider value={{
      currentToken,
      setCurrentToken,
      currentUser,
      setCurrentUser,
      currentCustomer,
      setCurrentCustomer,
      authorized,
      setAuthorized,
      isServerDown,
      isMaintenanceMode,
      isLoadingServerStatus, // Pass the loading state to context
      conversionRates,
      setConversionRates,
      countries,
      setCountries
    }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to use the AppContext
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
