import React from "react";

const Maintenance: React.FC = () => {
  const handleReload = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50 text-center px-4">
      <h1 className="text-5xl font-bold text-blue-600 mb-6">We'll be back soon!</h1>
      <p className="text-xl text-gray-700 mb-4">
        Our application is currently undergoing scheduled maintenance.
      </p>
      <p className="text-lg text-gray-600">
        We apologize for the inconvenience and appreciate your patience. Please check back later.
      </p>
      <p className="text-lg text-gray-600 mb-8">
        If you need immediate assistance, feel free to reach out to our support team.
      </p>
      <button
        onClick={handleReload}
        className="bg-violet-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg"
      >
        Try Again
      </button>
    </div>
  );
};

export default Maintenance;
