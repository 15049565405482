import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccounts } from "../../app.service";
import { useAppContext } from "../../AppContext";
import { DateTime } from "luxon";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import CreateAccount from "./CreateAccount";

interface Account {
  accountId: number;
  name: string;
  type: string;
  status: string;
  territory: string;
  customerId: number;
  ownerId: number;
  ownerName: string;
  created: string;
  updated: string;
  updatedBy: number;
}

const Accounts: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([]);
  const [showAddAccount, setShowAddAccount] = useState<boolean>(false);
  const { currentToken, currentCustomer, currentUser } = useAppContext();
  const navigate = useNavigate();

  // State for search and filters
  const [searchText, setSearchText] = useState<string>("");
  const [ownerFilter, setOwnerFilter] = useState<string>("My Accounts");
  const [territoryFilter, setTerritoryFilter] = useState<string>("All");
  const [statusFilter, setStatusFilter] = useState<string>("All");

  useEffect(() => {
    const fetchData = async () => {
      if (currentToken) {
        const result = await getAccounts(currentToken);

        if (result.success && Array.isArray(result.data)) {
          setAccounts(result.data);
          setFilteredAccounts(result.data);
        } else {
          console.error("Error fetching accounts:", result.message);
          setAccounts([]);
        }
      }
    };

    fetchData();
  }, [currentToken]);

  useEffect(() => {
    // Apply filters whenever search text or filters change
    let filtered = [...accounts];

    if (searchText) {
      filtered = filtered.filter((account) =>
        account.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (ownerFilter === "My Accounts") {
      filtered = filtered.filter(
        (account) => account.ownerId === currentUser.userId
      );
    } else if (ownerFilter === "Open Accounts") {
      filtered = filtered.filter((account) => !account.ownerId);
    }

    if (territoryFilter !== "All") {
      filtered = filtered.filter(
        (account) => account.territory === territoryFilter
      );
    }

    if (statusFilter !== "All") {
      filtered = filtered.filter((account) => account.status === statusFilter);
    }

    setFilteredAccounts(filtered);
  }, [
    searchText,
    ownerFilter,
    territoryFilter,
    statusFilter,
    accounts,
    currentUser,
  ]);

  const handleAddAccountClick = () => {
    setShowAddAccount(true);
  };

  const handleAddAccountSuccess = () => {
    setShowAddAccount(false);
    if (currentToken) {
      getAccounts(currentToken).then((result) => {
        if (result.success && Array.isArray(result.data)) {
          setAccounts(result.data);
        } else {
          console.error("Error refetching accounts:", result.message);
          setAccounts([]);
        }
      });
    }
  };

  const handleAccountClick = (accountId: number) => {
    navigate(`/accounts/${accountId}`);
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Top Bar */}
      <div className="flex justify-between items-center p-4 h-20 bg-white border-b">
        <h1 className="text-2xl font-bold">Accounts</h1>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Search Accounts..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="border p-2 rounded-lg text-sm"
          />
          <Select
            value={ownerFilter || undefined}
            onValueChange={setOwnerFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="My Accounts" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="My Accounts">My Accounts</SelectItem>
              <SelectItem value="All Accounts">All Accounts</SelectItem>
              <SelectItem value="Open Accounts">Open Accounts</SelectItem>
            </SelectContent>
          </Select>
          <Select
            value={territoryFilter || undefined}
            onValueChange={setTerritoryFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Select Territory" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Territories</SelectItem>
              <SelectItem value="North America">North America</SelectItem>
              <SelectItem value="EMEA">EMEA</SelectItem>
              <SelectItem value="APAC">APAC</SelectItem>
              <SelectItem value="LATAM">LATAM</SelectItem>
            </SelectContent>
          </Select>
          <Select
            value={statusFilter || undefined}
            onValueChange={setStatusFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Select Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Statuses</SelectItem>
              <SelectItem value="Not Contacted">Not Contacted</SelectItem>
              <SelectItem value="Contacted">Contacted</SelectItem>
              <SelectItem value="Disqualified">Disqualified</SelectItem>
              <SelectItem value="Ongoing Opportunity">
                Ongoing Opportunity
              </SelectItem>
            </SelectContent>
          </Select>
          <button
            className="bg-violet-500 text-white px-4 py-2 rounded-lg flex items-center gap-3"
            onClick={handleAddAccountClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Add Account
          </button>
        </div>
      </div>

      {/* Accounts List */}
      <div className="flex flex-col h-[90%] p-4 gap-2 overflow-auto bg-slate-50">
        {filteredAccounts.length > 0 ? (
          filteredAccounts.map((account) => (
            <div
              key={account.accountId}
              className="border rounded-lg p-4 cursor-pointer bg-white hover:bg-blue-50 flex flex-col gap-2"
              onClick={() => handleAccountClick(account.accountId)}
            >
              <div className="flex gap-4 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-10 stroke-violet-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                  />
                </svg>
                <h3 className="font-semibold">{account.name}</h3>
              </div>
              <div className="flex">
                <span className="flex gap-8 text-sm text-slate-500 items-center">
                  <div className="flex flex-col w-[14rem]">
                    <p className="font-bold">Type</p>
                    <p>{account.type}</p>
                  </div>
                  <div className="flex flex-col w-[14rem]">
                    <p className="font-bold">Status</p>
                    <p>{account.status}</p>
                  </div>
                  <div className="flex flex-col w-[14rem]">
                    <p className="font-bold">Territory</p>
                    <p>{account.territory}</p>
                  </div>
                  <div className="flex flex-col w-[14rem]">
                    <p className="font-bold">Owner</p>
                    <p>{account.ownerName}</p>
                  </div>
                  <div className="flex flex-col w-[14rem]">
                    <p className="font-bold">Created</p>
                    <p>{DateTime.fromISO(account.created).toFormat("yyyy-MM-dd")}</p>
                  </div>
                  <div className="flex flex-col w-[14rem]">
                    <p className="font-bold">Updated</p>
                    <p>{DateTime.fromISO(account.updated).toFormat("yyyy-MM-dd")}</p>
                  </div>
                </span>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No accounts available</p>
        )}
      </div>

      {/* Add Account Modal */}
      {showAddAccount && (
        <CreateAccount
          onClose={() => setShowAddAccount(false)}
          onSuccess={handleAddAccountSuccess}
        />
      )}
    </div>
  );
};

export default Accounts;
