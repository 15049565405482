import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import { DateTime } from "luxon";
import { getContracts } from "../../app.service"; // Assume you have a function to fetch contracts
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { formatNumberWithThousandsSeparator } from "../methods";

interface Contract {
  contractLines: any;
  contractId: number;
  contractName: string;
  customerName: string;
  contractType: string;
  contractStatus: string;
  contractStartDate: string;
  contractEndDate: string;
  contractCurrency: string;
}

const Contracts: React.FC = () => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [filteredContracts, setFilteredContracts] = useState<Contract[]>([]);
  const { currentToken } = useAppContext();
  const navigate = useNavigate();

  // State for search and filters
  const [searchText, setSearchText] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("Active");
  const [endDateFilter, setEndDateFilter] = useState<string>("All");

  const fetchData = async () => {
    if (currentToken) {
      const result = await getContracts(currentToken);

      if (result.success && Array.isArray(result.data)) {
        setContracts(result.data);
        setFilteredContracts(result.data);
      } else {
        console.error("Error fetching contracts:", result.message);
        setContracts([]);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentToken]);

  useEffect(() => {
    // Apply filters whenever search text or filters change
    let filtered = [...contracts];

    if (searchText) {
      filtered = filtered.filter((contract) =>
        contract.contractName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (statusFilter !== "All") {
      filtered = filtered.filter(
        (contract) => contract.contractStatus === statusFilter
      );
    }

    if (endDateFilter !== "All") {
      const now = DateTime.now();
      filtered = filtered.filter((contract) => {
        const endDate = DateTime.fromISO(contract.contractEndDate);
        if (endDateFilter === "This Year") {
          return endDate.year === now.year;
        } else if (endDateFilter === "Next 6 Months") {
          return endDate.diff(now, "months").months <= 6 && endDate > now;
        }
        return true;
      });
    }

    setFilteredContracts(filtered);
  }, [searchText, statusFilter, endDateFilter, contracts]);

  return (
    <div className="flex h-screen w-full items-center justify-center text-2xl font-bold">
      <p className="flex w-full h-full items-center justify-center">IN DEVELOPMENT 🚀</p> 
    </div>
  )

  return (
    <div className="flex flex-col h-screen">
      {/* Top Bar */}
      <div className="flex justify-between items-center p-4 h-20 bg-white border-b">
        <h1 className="text-2xl font-bold">Contracts</h1>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Search Contracts..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="border p-2 rounded-lg text-sm"
          />
          <Select
            value={statusFilter || undefined}
            onValueChange={setStatusFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Select Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Statuses</SelectItem>
              <SelectItem value="Draft">Draft</SelectItem>
              <SelectItem value="Active">Active</SelectItem>
              <SelectItem value="Expired">Expired</SelectItem>
              <SelectItem value="Terminated">Terminated</SelectItem>
            </SelectContent>
          </Select>
          <Select
            value={endDateFilter || undefined}
            onValueChange={setEndDateFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Filter End Date" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All</SelectItem>
              <SelectItem value="This Year">This Year</SelectItem>
              <SelectItem value="Next 6 Months">Next 6 Months</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Contracts List */}
      <div className="flex flex-col h-[90%] p-4 gap-2 overflow-auto bg-slate-50">
        {filteredContracts.length > 0 ? (
          filteredContracts.map((contract) => {
            let annualRecurringRevenue = 0;

            // Filter contractLines where endDate matches contractEndDate and revenueType is "Recurring"
            if (contract.contractLines) {
              const filteredContractLines = contract.contractLines.filter(
                (line: { endDate: string; revenueType: string }) => {
                  return (
                    line.endDate === contract.contractEndDate &&
                    line.revenueType === "Recurring"
                  );
                }
              );

              // Calculate the sum of quantity * salesPrice for filtered contract lines
              annualRecurringRevenue = filteredContractLines.reduce(
                (
                  sum: number,
                  line: { quantity: number; salesPrice: number }
                ) => {
                  return sum + line.quantity * line.salesPrice;
                },
                0
              );
            }

            return (
              <div
                key={contract.contractId}
                className="border rounded-lg p-4 bg-white hover:bg-blue-50 flex items-center cursor-pointer"
                onClick={() => navigate(`/contracts/${contract.contractId}`)}
              >
                <div className="flex justify-between w-full">
                  <div className="flex w-full">
                    <div className="flex flex-col w-full">
                      <h3 className="font-semibold">{contract.contractName}</h3>
                      <div className="flex w-full gap-8 text-sm text-slate-500 mt-2">
                        <div>
                          <p className="font-bold w-32">Customer</p>
                          <p>{contract.customerName}</p>
                        </div>
                        <div>
                          <p className="font-bold w-32">Type</p>
                          <p>{contract.contractType}</p>
                        </div>
                        <div>
                          <p className="font-bold w-32">Status</p>
                          <p>{contract.contractStatus}</p>
                        </div>
                        <div>
                          <p className="font-bold w-32">Start Date</p>
                          <p>
                            {DateTime.fromISO(
                              contract.contractStartDate
                            ).toFormat("yyyy-MM-dd")}
                          </p>
                        </div>
                        <div>
                          <p className="font-bold w-32">End Date</p>
                          <p>
                            {DateTime.fromISO(
                              contract.contractEndDate
                            ).toFormat("yyyy-MM-dd")}
                          </p>
                        </div>
                        <div>
                          <p className="font-bold w-48">
                            Annual Recurring Revenue
                          </p>
                          <p>
                            {annualRecurringRevenue
                              ? formatNumberWithThousandsSeparator(
                                  annualRecurringRevenue,
                                  ` ${contract.contractCurrency}`
                                )
                              : " - "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-gray-500">No contracts available</p>
        )}
      </div>
    </div>
  );
};

export default Contracts;
