import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Toaster } from 'react-hot-toast'; // Import Toaster for toast notifications

import Collateral from "./components/Collateral/Collateral";
import "./styles/globals.css";
import { useAppContext } from "../src/AppContext";
import Home from "./components/Home/Home";
import Sidebar from "./components/Sidebar/Sidebar";
import Login from "./components/Login/Login";
import Accounts from "./components/Accounts/Accounts";
import AccountDetail from "./components/Accounts/AccountDetail";
import Maintenance from "./components/Maintenance/Maintenance";
import Leads from "./components/Leads/Leads";
import Contracts from "./components/Contracts/Contracts";

const auth = getAuth();

const App: React.FC = () => {
  const [user, loading] = useAuthState(auth);
  const {
    isServerDown,
    isMaintenanceMode,
    authorized,
    currentUser,
    isLoadingServerStatus,
  } = useAppContext();

  // Display loading screen while checking server status or maintenance mode
  if (loading || isLoadingServerStatus) {
    return <div></div>; // You can customize this loading screen
  }

  // Render the Maintenance component if the server is down or in maintenance mode
  if (isServerDown || isMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <Router>
      <div className="flex">
        {currentUser && authorized && <Sidebar />}
        <div className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                currentUser && authorized ? (
                  <Home />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/login" element={<Login />} />
            {currentUser && authorized && (
              <>
                <Route path="/accounts/:accountId" element={<AccountDetail />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/collateral" element={<Collateral />} />
                <Route path="/contracts" element={<Contracts />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </div>
      </div>

      {/* Add Toaster component here to handle global toast notifications */}
      <Toaster position="top-left" reverseOrder={false} />
    </Router>
  );
};

export default App;
