import toast from 'react-hot-toast'

function success(title: string, body?: string) {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? 'left-0' : '-left-[30rem]'
        } z-[100] max-w-md w-full bg-gray-100 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1  relative transition-all duration-500 ease-in-out`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0 pt-0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8 fill-green-500"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            {body ? (
              <div className="ml-4 flex-1">
                <p className="text-sm font-medium text-gray-900">{title}</p>
                <p className="mt-1 text-sm text-gray-500">{body}</p>
              </div>
            ) : (
              <div className="ml-4 flex-1">
                <p className="text-sm font-medium text-gray-900">{title}</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => {
              toast.dismiss(t.id)
            }}
            className="w-full border border-transparent hover:bg-slate-200 rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium"
          >
            Close
          </button>
        </div>
      </div>
    ),
    { id: 'success' },
  )
}

function warning(title: string, body?: string) {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'left-0' : '-left-[30rem]'
      } z-[100] max-w-md w-full bg-gray-100 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1  relative transition-all duration-500 ease-in-out`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-8 h-8 fill-amber-500"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {body ? (
            <div className="ml-4 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{body}</p>
            </div>
          ) : (
            <div className="ml-4 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent hover:bg-slate-200 rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium"
        >
          Close
        </button>
      </div>
    </div>
  ))
}

function error(title: string, body?: string) {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'left-0' : '-left-[30rem]'
      } z-[100] max-w-md w-full bg-gray-100 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1  relative transition-all duration-500 ease-in-out`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-8 h-8 fill-red-500"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {body ? (
            <div className="ml-4 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{body}</p>
            </div>
          ) : (
            <div className="ml-4 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent hover:bg-slate-200 rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium"
        >
          Close
        </button>
      </div>
    </div>
  ))
}

function info(title: string, body?: string) {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'left-0' : '-left-[30rem]'
      } z-[100] max-w-md w-full bg-gray-100 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1  relative transition-all duration-500 ease-in-out`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-8 h-8 fill-sky-500"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {body ? (
            <div className="ml-4 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{body}</p>
            </div>
          ) : (
            <div className="ml-4 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent hover:bg-slate-200 rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium"
        >
          Close
        </button>
      </div>
    </div>
  ))
}

export const notificationType = {
  success,
  warning,
  error,
  info,
}
