import React, { useEffect, useState } from "react";
import { Dialog, DialogClose } from "@radix-ui/react-dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  createContact,
  updateContact,
  deleteContact,
  getMarketingMessages,
} from "../../app.service";
import { useAppContext } from "../../AppContext";
import { truncateString } from "../methods";
import { DateTime } from "luxon";

interface ContactDetailsProps {
  selectedContact: any; // Replace `any` with the correct type of your contact object
  clearSelectedContact: () => void; // Callback function to clear the selected contact
}

const MarketingDetails: React.FC<ContactDetailsProps> = ({
  selectedContact,
  clearSelectedContact,
}) => {
  const { currentToken } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState<string>(
    selectedContact?.firstName || ""
  );
  const [lastName, setLastName] = useState<string>(
    selectedContact?.lastName || ""
  );
  const [title, setTitle] = useState<string>(selectedContact?.title || "");
  const [phone, setPhone] = useState<string>(selectedContact?.phone || "");
  const [email, setEmail] = useState<string>(selectedContact?.email || "");
  const [isEditing, setIsEditing] = useState<boolean>(!selectedContact);
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    if (currentToken) {
      setIsOpen(true);
      fetchMessages();
    }
  }, [selectedContact, currentToken]);

  const fetchMessages = async () => {
    // Fetch users
    if (currentToken && selectedContact && selectedContact?.email) {
      const result = await getMarketingMessages(
        currentToken,
        selectedContact?.email
      );
      if (result.success) {
        setMessages(result.data);
      }
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      clearSelectedContact(); // Clear the selectedContact when closing
    }
  };

  const closeSheet = () => {
    setIsOpen(false);
    clearSelectedContact(); // Call the parent callback to clear selectedContact
  };

  return (
    <>
      <Sheet open={isOpen} onOpenChange={handleOpenChange}>
        <SheetContent side="right" className="w-[850px] !max-w-none">
          <SheetHeader className="h-full">
            <SheetTitle className="text-2xl mt-4">
              {selectedContact?.firstName + " " + selectedContact?.lastName}
            </SheetTitle>
            <div className="flex flex-col justify-between h-full pt-10">
              <div className="flex flex-col h-auto gap-4">
                <div className="flex gap-4 mt-6">
                  <span className="flex flex-col gap-1 w-full">
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                </div>
                <div className="flex gap-4 mt-6">
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Title</Label>
                    <Input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                </div>
                <div className="flex gap-4 mt-6">
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Phone</Label>
                    <Input
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                  <span className="flex flex-col gap-1 w-full">
                    <Label>Email</Label>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={!isEditing}
                    />
                  </span>
                </div>
                <div className="flex flex-col h-[28rem] overflow-y-auto p-4 border rounded-lg gap-2">
                  {messages.map((message) => (
                    <div className="flex bg-neutral-100 p-2 text-xs" key={message.UUID}>
                      <span
                        title={message.Subject}
                        className="text-sm w-[35rem] font-semibold text-slate-900"
                      >
                        {truncateString(message.Subject, 60)}
                      </span>
                      <span
                        title={message.Status}
                        className="text-sm font-semibold text-slate-900"
                      >
                        {message.Status === "sent" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                            />
                          </svg>
                        )}

                        {message.Status === "opened" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                            />
                          </svg>
                        )}

                        {message.Status === "clicked" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672Zm-7.518-.267A8.25 8.25 0 1 1 20.25 10.5M8.288 14.212A5.25 5.25 0 1 1 17.25 10.5"
                            />
                          </svg>
                        )}
                      </span>
                      <span className="px-4 text-sm text-slate-500">
                        {DateTime.fromISO(message.ArrivedAt).toFormat(
                          "yyyy-MM-dd HH:MM"
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex gap-4 mt-6">
                <DialogClose
                  className="bg-gray-400 text-white px-4 py-2 rounded"
                  onClick={closeSheet}
                >
                  Close
                </DialogClose>
              </div>
            </div>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default MarketingDetails;
