import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import { DateTime } from "luxon";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  disqualifyLead,
  getLeads,
  qualifyLead,
  revertLead,
} from "../../app.service";
import { truncateString } from "../methods";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import Spinner from "../ui/spinner";
import MarketingDetails from "../Marketing/MarketingDetail";
import { notificationType } from "../notification.type";

interface Lead {
  leadId: number;
  firstName: string;
  lastName: string;
  name: string;
  company: string;
  title: string;
  phone: string;
  email: string;
  country: string;
  territory: string;
  leadSource: string;
  status: string;
  created: string;
  updated: string;
  updatedBy: number;
}

const Leads: React.FC = () => {
  const { currentToken, countries, currentUser } = useAppContext();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([]);
  const [showAddAccount, setShowAddAccount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedMarketingContact, setSelectedMarketingContact] =
    useState<any>(null);

  const navigate = useNavigate();

  // State for search and filters
  const [searchText, setSearchText] = useState<string>("");
  const [ownerFilter, setOwnerFilter] = useState<string>("My Leads");
  const [territoryFilter, setTerritoryFilter] = useState<string>("All");
  const [sourceFilter, setSourceFilter] = useState<string>("All");
  const [statusFilter, setStatusFilter] = useState<string>("New");

  const fetchData = async () => {
    if (currentToken) {
      const result = await getLeads(currentToken);

      if (result.success && Array.isArray(result.data)) {
        setLeads(result.data);
        setFilteredLeads(result.data);
        setLoading(false);
      } else {
        console.error("Error fetching leads:", result.message);
        setLeads([]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentToken]);

  useEffect(() => {
    // Apply filters whenever search text or filters change
    let filtered = [...leads];

    if (searchText) {
      filtered = filtered.filter((lead) =>
        lead.name.toLowerCase().includes(searchText.toLowerCase()) ||
        lead.company.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (territoryFilter !== "All") {
      filtered = filtered.filter((lead) => {
        const country = countries.find(
          (c: { country: string }) => c.country === lead.country
        );
        return country && country.territory === territoryFilter;
      });
    }

    if (statusFilter !== "All") {
      filtered = filtered.filter((lead) => lead.status === statusFilter);
    }

    if (sourceFilter !== "All") {
      filtered = filtered.filter((lead) => lead.leadSource === sourceFilter);
    }

    setFilteredLeads(filtered);
  }, [
    searchText,
    territoryFilter,
    statusFilter,
    sourceFilter,
    leads,
    currentUser,
  ]);

  async function handleQualified(leadId: number) {
    if (currentToken) {
      const result = await qualifyLead(currentToken, leadId);

      if (result.success) {
        navigate(`/accounts/${result.data.accountId}`);
      } else {
        notificationType.error("Error!", result.message);
      }
    }
  }

  async function handleDisqualified(leadId: number) {
    if (currentToken) {
      const result = await disqualifyLead(currentToken, leadId);
      if (result.success) {
        fetchData();
      } else {
        notificationType.error("Error!", result.message);
      }
    }
  }

  async function handleReverted(leadId: number) {
    if (currentToken) {
      const result = await revertLead(currentToken, leadId);
      if (result.success) {
        notificationType.success("Success!", result.message);
        fetchData();
      } else {
        notificationType.error("Error!", result.message);
      }
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  function handleMarketingDetailClick(lead: Lead) {
    setSelectedMarketingContact(lead);
  }

  const clearSelectedMarketingContact = () => {
    setSelectedMarketingContact(null);
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Top Bar */}
      <div className="flex justify-between items-center p-4 h-20 bg-white border-b">
        <h1 className="text-2xl font-bold">Leads</h1>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Search Leads..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="border p-2 rounded-lg text-sm"
          />
          <Select
            value={territoryFilter || undefined}
            onValueChange={setTerritoryFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Select Territory" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Territories</SelectItem>
              <SelectItem value="North America">North America</SelectItem>
              <SelectItem value="EMEA">EMEA</SelectItem>
              <SelectItem value="APAC">APAC</SelectItem>
              <SelectItem value="LATAM">LATAM</SelectItem>
            </SelectContent>
          </Select>
          <Select
            value={statusFilter || undefined}
            onValueChange={setStatusFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Select Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Statuses</SelectItem>
              <SelectItem value="New">New</SelectItem>
              <SelectItem value="Qualified">Qualified</SelectItem>
              <SelectItem value="Disqualified">Disqualified</SelectItem>
            </SelectContent>
          </Select>
          <Select
            value={sourceFilter || undefined}
            onValueChange={setSourceFilter}
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Select Source" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Sources</SelectItem>
              <SelectItem value="Sourced">Sourced</SelectItem>
              <SelectItem value="Inbound">Inbound</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Leads List */}
      {leads.length > 0 ? (
        <Table>
          <TableHeader className="sticky top-0 bg-white">
            <TableRow>
              <TableHead className="w-[400px]">Name</TableHead>
              <TableHead>Phone</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Country</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Created</TableHead>
              <TableHead className="text-right"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="text-xs">
            {filteredLeads.length > 0
              ? filteredLeads.map((lead) => (
                  <TableRow key={lead.leadId}>
                    <TableCell className="font-medium">
                      <div className="flex gap-2 items-center">
                        <div className="w-18">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-8 stroke-violet-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-bold ">
                            {" "}
                            {lead.name}
                            {" | "}
                            {lead.company}
                          </p>
                          <p className="font-normal text-xs text-slate-500">
                            {lead.title}
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <a
                        href={`tel:${lead.phone}`}
                        className="text-blue-500 underline justify-start"
                      >
                        {lead.phone}
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        href={`mailto:${lead.email}`}
                        className="text-blue-500 underline justify-start"
                      >
                        {lead.email}
                      </a>
                    </TableCell>
                    <TableCell>{lead.country}</TableCell>
                    <TableCell>{lead.status}</TableCell>
                    <TableCell>
                      {DateTime.fromISO(lead.created).toFormat("yyyy-MM-dd")}
                    </TableCell>
                    <TableCell>
                      <button
                        onClick={() => {
                          handleMarketingDetailClick(lead);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                          />
                        </svg>
                      </button>
                    </TableCell>
                    <TableCell className="text-right">
                      {" "}
                      <div className="flex items-center justify-end ">
                        {lead.status === "Disqualified" ? (
                          <button
                            className="text-white w-20 text-sm px-2 py-1 first:rounded-l-lg last:rounded-r-lg bg-green-400"
                            onClick={() => {
                              handleReverted(lead.leadId);
                            }}
                          >
                            Revert
                          </button>
                        ) : (
                          <>
                            <button
                              className={`text-white w-20 text-sm px-2 py-1 first:rounded-l-lg last:rounded-r-lg
                                ${lead.status === "Qualified" ? "bg-neutral-400" : "bg-violet-500"}
                              `}
                              disabled={lead.status === "Qualified"}
                              onClick={() => {
                                handleQualified(lead.leadId);
                              }}
                            >
                              Accept
                            </button>
                            <button
                              className={`text-white w-20 text-sm px-2 py-1 first:rounded-l-lg last:rounded-r-lg
                                ${lead.status === "Qualified" ? "bg-neutral-400" : "bg-red-400"}
                              `}
                              disabled={lead.status === "Qualified"}
                              onClick={() => {
                                handleDisqualified(lead.leadId);
                              }}
                            >
                              Disqualify
                            </button>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      ) : (
        <div className="flex justify-center items-center h-full">
          <p className="text-lg text-gray-400">No leads found</p>
        </div>
      )}
      {selectedMarketingContact && (
        <MarketingDetails
          selectedContact={selectedMarketingContact}
          clearSelectedContact={clearSelectedMarketingContact}
        />
      )}
    </div>
  );
};

export default Leads;
