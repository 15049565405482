import React, { useEffect, useState } from "react";
import { DialogClose } from "@radix-ui/react-dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { Input } from "../ui/input";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { DateTime } from "luxon";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useAppContext } from "../../AppContext";
import { cancelPendingTermination, createOpportunity, getProducts, terminateContract } from "../../app.service";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { notificationType } from "../notification.type";

interface ContractDetailsProps {
  selectedContract: any;
  clearSelectedContract: () => void;
  accountName: string;
  accountId: number;
}

interface ContractLine {
  productName: string;
  contractLineId: number;
  previousContractLineId: number;
  isEditing: boolean;
  isUpdated: boolean;
  productType: string;
  startDate: string;
  endDate: string;
  quantity: number;
  salesPrice: number;
  revenueType: string;
  quantityUnitOfMeasure: string;
  overagePrice: number | null;
}

interface Product {
  contractLineId: number | null;
  productId: number;
  productName: string;
  description: string;
  productType: string;
  listPrices: {
    EUR: number;
    SEK: number;
    USD: number;
  };
  revenueType: string;
  quantityUnitOfMeasure: string;
  billingFrequency: string;
  active: number;
  created: string;
  updated: string;
  updatedBy: number;
  isEditing: boolean;
  quantity: number;
  salesPrice: number | null;
  overagePrice: number;
  isRenewing: boolean | null;
  isAdded: boolean;
  change: "Updated" | "Added" | "Renewed" | null;
}

const ContractDetails: React.FC<ContractDetailsProps> = ({
  selectedContract,
  clearSelectedContract,
  accountName,
  accountId,
}) => {
  const { currentToken, currentUser, conversionRates } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [contractType, setContractType] = useState<string>(
    selectedContract?.contractType || ""
  );
  const [contractId, setContractId] = useState<number>(
    selectedContract?.contractId || ""
  );
  const [previousAnnualRecurringRevenue, setPreviousAnnualRecurringRevenue] =
    useState<number>(selectedContract?.annualRecurringRevenue || 0);
  const [contractStatus, setContractStatus] = useState<string>(
    selectedContract?.contractStatus || ""
  );
  const [contractStartDate, setContractStartDate] = useState<string>(
    DateTime.fromISO(selectedContract?.contractStartDate).toISODate() || ""
  );
  const [contractEndDate, setContractEndDate] = useState<string>(
    DateTime.fromISO(selectedContract?.contractEndDate).toISODate() || ""
  );
  const [contractTerm, setContractTerm] = useState<string>(
    `${selectedContract?.contractTerm || ""} months`
  );
  const [noticePeriod, setNoticePeriod] = useState<string>(
    `${selectedContract?.noticePeriod || ""} days`
  );
  const [autoRenewal, setAutoRenewal] = useState<boolean>(
    selectedContract?.autoRenewal || false
  );
  const [paymentTerm, setPaymentTerm] = useState<string>(
    `${selectedContract?.paymentTerm || ""} days`
  );
  const [contractCurrency, setContractCurrency] = useState<
    "SEK" | "USD" | "EUR"
  >(selectedContract?.contractCurrency || "");
  const [contractLines, setContractLines] = useState<ContractLine[]>(
    selectedContract?.contractLines || []
  );
  const [amendmentOpen, setAmendmentOpen] = useState<boolean>(false);
  const [renewalOpen, setRenewalOpen] = useState<boolean>(false);
  const [terminationOpen, setTerminationOpen] = useState<boolean>(false);

  const [amendmentStartDate, setAmendmentStartDate] = useState<string>("");

  const [activeProducts, setActiveProducts] = useState<any[]>([]);
  const [initialActiveProducts, setInitialActiveProducts] = useState<any[]>([]);

  const [products, setProducts] = useState<Product[]>([]);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [initialAvailableProducts, setInitialAvailableProducts] = useState<
    Product[]
  >([]);

  const [productsFetched, setProductsFetched] = useState<boolean>(false);

  const [updateDisabled, setUpdateDisabled] = useState(true);

  const [renewalContractTerm, setRenewalContractTerm] = useState<number>(
    selectedContract?.contractTerm
  );

  const [renewalNoticePeriod, setRenewalNoticePeriod] = useState<number>(
    selectedContract?.noticePeriod
  );

  const [renewalPaymentTerm, setRenewalPaymentTerm] = useState<number>(
    selectedContract?.paymentTerm
  );

  const [renewalAutoRenewal, setRenewalAutoRenwal] = useState<boolean>(
    selectedContract?.autoRenewal
  );

  const [renewalContractError, setRenewalContractError] =
    useState<boolean>(false);

  const [renewedProducts, setRenewedProducts] = useState<Product[]>([]);
  const [initialRenewedProducts, setInitialRenewedProducts] = useState<
    Product[]
  >([]);

  useEffect(() => {
    setIsOpen(true);
  }, [selectedContract]);

  const fetchProducts = async () => {
    if (currentToken) {
      const products = await getProducts(currentToken);
      setProducts(products.data);
      let productsArr;
      if (activeProducts.length > 0) {
        productsArr = products.data.filter(
          (product: { productName: any }) =>
            !activeProducts.some(
              (activeProduct) =>
                activeProduct.productName === product.productName
            )
        );
        setProductsFetched(true);
      } else {
        productsArr = products.data;
      }
      setInitialAvailableProducts(productsArr);
      setAvailableProducts(productsArr);
    }
  };

  useEffect(() => {
    if (selectedContract.contractLines.length > 0) {
      let licenseCount = 0;
      let licensePrice = 0;
      let nonRecurringRevenue = 0;
      selectedContract.contractLines.forEach(
        (product: {
          revenueType: string;
          productName: string;
          quantity: number;
          salesPrice: number;
        }) => {
          if (product.revenueType === "Recurring") {
            if (product.productName === "Intelly Platform") {
              licenseCount = product.quantity;
            }
            licensePrice += product.salesPrice;
          } else if (product.revenueType === "Non-recurring") {
            nonRecurringRevenue += product.salesPrice * product.quantity;
          }
        }
      );

      const recurringRevenue = licensePrice * licenseCount;
      setPreviousAnnualRecurringRevenue(recurringRevenue);

      const filteredContractLines = selectedContract.contractLines.filter(
        (contractLine: { endDate: any; revenueType: string }) =>
          contractLine.endDate === selectedContract.contractEndDate &&
          contractLine.revenueType === "Recurring"
      );
      setInitialActiveProducts(filteredContractLines);
      setActiveProducts(filteredContractLines);
    }
  }, [selectedContract.contractLines]);

  useEffect(() => {
    if (!productsFetched) {
      fetchProducts();
    }
  }, [activeProducts]);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      clearSelectedContract();
      closeModal();
    }
  };

  const handleEditClick = (index: number) => {
    setActiveProducts((prevProducts: ContractLine[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          return { ...product, isEditing: !product.isEditing };
        } else if (
          product.productType === "Module" &&
          prevProducts[index].productType === "License"
        ) {
          return { ...product, quantity: prevProducts[index].quantity };
        }
        return product;
      });
    });
  };

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newQuantity = parseInt(e.target.value, 10);
    setActiveProducts((prevProducts: ContractLine[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          return { ...product, quantity: newQuantity, isUpdated: true };
        } else if (product.productType === "Module") {
          return { ...product, quantity: newQuantity, isUpdated: true };
        }
        return product;
      });
    });

    // Update available products for modules if the updated product is a License and isAdded is true
    if (activeProducts[index].productType === "License") {
      setAvailableProducts((prevAvailableProducts: Product[]) => {
        return prevAvailableProducts.map((product) => {
          if (product.productType === "Module" && product.isAdded) {
            return { ...product, quantity: newQuantity };
          }
          return product;
        });
      });
    }
  };

  const handleSalesPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newSalesPrice = parseFloat(e.target.value);
    setActiveProducts((prevProducts: ContractLine[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          return { ...product, salesPrice: newSalesPrice };
        }
        return product;
      });
    });
  };

  const handleOveragePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newOveragePrice = parseFloat(e.target.value);
    setActiveProducts((prevProducts: ContractLine[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          return { ...product, overagePrice: newOveragePrice };
        }
        return product;
      });
    });
  };

  const handleCheckboxChange = (index: number) => {
    setAvailableProducts((prevProducts: Product[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          const updatedProduct = {
            ...product,
            isAdded: !product.isAdded,
            isEditing: true,
          };

          if (updatedProduct.isAdded) {
            updatedProduct.salesPrice =
              products.find((p) => p.productId === updatedProduct.productId)
                ?.listPrices[contractCurrency] || 0;
          } else {
            updatedProduct.salesPrice = null;
          }

          // If the product is of type "Module" and is being added, set its quantity to match the active license
          if (
            updatedProduct.productType === "Module" &&
            updatedProduct.isAdded
          ) {
            const licenseProduct = activeProducts.find(
              (p) => p.productType === "License"
            );
            if (licenseProduct) {
              updatedProduct.quantity = licenseProduct.quantity;
            }
          }
          return updatedProduct;
        }
        return product;
      });
    });
  };

  const handleAvailableQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newQuantity = parseInt(e.target.value, 10);
    setAvailableProducts((prevProducts: Product[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          return { ...product, quantity: newQuantity };
        }
        return product;
      });
    });
  };

  const handleAvailableSalesPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newSalesPrice = parseFloat(e.target.value);
    setAvailableProducts((prevProducts: Product[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          return { ...product, salesPrice: newSalesPrice };
        }
        return product;
      });
    });
  };

  const handleAvailableOveragePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newOveragePrice = parseFloat(e.target.value);
    setAvailableProducts((prevProducts: Product[]) => {
      return prevProducts.map((product, idx) => {
        if (idx === index) {
          return { ...product, overagePrice: newOveragePrice };
        }
        return product;
      });
    });
  };

  const handleProductChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    product: Product,
    index: number,
    type: "Renewable" | "Available",
    field: "checkbox" | "quantity" | "salesPrice" | "overagePrice"
  ) => {
    const targetArray =
      type === "Renewable" ? renewedProducts : availableProducts;
    const oppositeArray =
      type === "Renewable" ? availableProducts : renewedProducts;
    const updatedProducts = [...targetArray];
    const oppositeProducts = [...oppositeArray];

    if (field === "checkbox") {
      // Handle checkbox change for renewable products
      if (type === "Renewable") {
        updatedProducts[index].isRenewing = !updatedProducts[index].isRenewing;
      } else {
        updatedProducts[index].isAdded = !updatedProducts[index].isAdded;
      }
    }

    if (field === "quantity") {
      // Update the quantity for the current product
      const newQuantity = parseInt(e.target.value, 10);
      updatedProducts[index].quantity = newQuantity;

      // If the updated product is of type "License", update the quantity of all "Module" products
      if (product.productType === "License") {
        updatedProducts.forEach((p) => {
          if (p.productType === "Module") {
            p.quantity = newQuantity; // Sync the quantity with the License product
          }
        });
        oppositeProducts.forEach((p) => {
          if (p.productType === "Module") {
            p.quantity = newQuantity; // Sync the quantity with the License product
          }
        });
        if (type === "Renewable") {
          setAvailableProducts(oppositeProducts);
        } else {
          setRenewedProducts(oppositeProducts);
        }
      }
    }

    if (field === "salesPrice") {
      updatedProducts[index].salesPrice = parseFloat(e.target.value);
    }

    if (field === "overagePrice") {
      updatedProducts[index].overagePrice = parseFloat(e.target.value);
    }

    // Update the state of the products based on whether they are Renewable or Available
    if (type === "Renewable") {
      setRenewedProducts(updatedProducts);
    } else {
      setAvailableProducts(updatedProducts);
    }
  };

  // Populate the renewedProducts array with activeProducts on component mount
  useEffect(() => {
    const populatedRenewedProducts = activeProducts.map((product) => ({
      ...product,
      isRenewing: true, // All products are checked by default
    }));
    setRenewedProducts(populatedRenewedProducts);
    setInitialRenewedProducts(populatedRenewedProducts);
  }, [activeProducts]);

  useEffect(() => {
    if (
      amendmentOpen &&
      amendmentStartDate &&
      (activeProducts.some((product) => product.isUpdated) ||
        availableProducts.some((product) => product.isAdded))
    ) {
      setUpdateDisabled(false);
    } else if (
      renewalOpen &&
      renewalContractTerm &&
      renewalNoticePeriod &&
      renewalPaymentTerm &&
      renewedProducts.some((product) => product.isRenewing)
    ) {
      setUpdateDisabled(false);
    } else {
      setUpdateDisabled(true);
    }
  }, [
    amendmentStartDate,
    activeProducts,
    availableProducts,
    renewedProducts,
    renewalContractTerm,
    renewalNoticePeriod,
    renewalPaymentTerm,
    amendmentOpen,
    renewalOpen,
  ]);

  async function handleCreateOpportunity(): Promise<void> {
    const opportunityProducts: {
      change: "Added" | "Updated" | "Renewed" | null;
      productType: string;
      quantity: number;
      listPrice: number | null;
      productId: number | null;
      salesPrice: number | null;
      description: string | null;
      productName: string;
      revenueType: string;
      overagePrice: number | null;
      quantityUnitOfMeasure: string;
      previousContractLineId: number | null;
    }[] = [];

    if (amendmentOpen) {
      activeProducts.forEach((product: ContractLine) => {
        if (product.isUpdated) {
          opportunityProducts.push({
            quantity: product.quantity,
            listPrice:
              products.find((p) => p.productName === product.productName)
                ?.listPrices[contractCurrency] || null,
            productId:
              products.find((p) => p.productName === product.productName)
                ?.productId || null,
            salesPrice: product.salesPrice,
            description:
              products.find((p) => p.productName === product.productName)
                ?.description || null,
            productName: product.productName,
            revenueType: product.revenueType,
            overagePrice: product.overagePrice,
            productType: product.productType,
            quantityUnitOfMeasure: product.quantityUnitOfMeasure,
            previousContractLineId: product.contractLineId,
            change: "Updated",
          });
        }
      });

      availableProducts.forEach((product: Product) => {
        if (product.isAdded) {
          opportunityProducts.push({
            quantity: product.quantity,
            listPrice:
              products.find((p) => p.productName === product.productName)
                ?.listPrices[contractCurrency] || null,
            productId:
              products.find((p) => p.productName === product.productName)
                ?.productId || null,
            salesPrice: product.salesPrice
              ? product.revenueType === "Recurring"
                ? product.salesPrice
                : product.salesPrice
              : null,
            description:
              products.find((p) => p.productName === product.productName)
                ?.description || null,
            productName: product.productName,
            revenueType: product.revenueType,
            overagePrice: product.overagePrice,
            productType: product.productType,
            quantityUnitOfMeasure: product.quantityUnitOfMeasure,
            previousContractLineId: null,
            change: "Added",
          });
        }
      });

      const opportunityObj = {
        name: `${accountName} - Up-sell - ${DateTime.utc().year}`,
        type: "Up-sell",
        stage: "Prospecting",
        currency: contractCurrency,
        products: opportunityProducts,
        closeDate: DateTime.utc().plus({ days: 30 }).toISODate(),
        contractStartDate: amendmentStartDate,
        previousContractId: contractId,
        annualRecurringRevenue: null,
        nextSteps: "",
        nonRecurringRevenue: null,
        previousAnnualRecurringRevenue: previousAnnualRecurringRevenue,
        accountId: accountId,
        ownerId: currentUser.userId,
      };

      if (currentToken) {
        const opportunityResult = await createOpportunity(
          opportunityObj,
          currentToken
        );
        if (opportunityResult.success) {
          notificationType.success("Success!", opportunityResult.message);
          handleOpenChange(false);
        } else {
          notificationType.error("Error!", opportunityResult.message);
        }
      }
    }

    if (renewalOpen) {
      renewedProducts.forEach((product: Product) => {
        if (product.isRenewing) {
          opportunityProducts.push({
            quantity: product.quantity,
            listPrice:
              products.find((p) => p.productName === product.productName)
                ?.listPrices[contractCurrency] || null,
            productId:
              products.find((p) => p.productName === product.productName)
                ?.productId || null,
            salesPrice: product.salesPrice,
            description:
              products.find((p) => p.productName === product.productName)
                ?.description || null,
            productName: product.productName,
            revenueType: product.revenueType,
            overagePrice: product.overagePrice,
            productType: product.productType,
            quantityUnitOfMeasure: product.quantityUnitOfMeasure,
            previousContractLineId: product.contractLineId,
            change: "Renewed",
          });
        }
      });

      availableProducts.forEach((product: Product) => {
        if (product.isAdded) {
          opportunityProducts.push({
            quantity: product.quantity,
            listPrice:
              products.find((p) => p.productName === product.productName)
                ?.listPrices[contractCurrency] || null,
            productId:
              products.find((p) => p.productName === product.productName)
                ?.productId || null,
            salesPrice: product.salesPrice
              ? product.revenueType === "Recurring"
                ? product.salesPrice
                : product.salesPrice
              : null,
            description:
              products.find((p) => p.productName === product.productName)
                ?.description || null,
            productName: product.productName,
            revenueType: product.revenueType,
            overagePrice: product.overagePrice,
            productType: product.productType,
            quantityUnitOfMeasure: product.quantityUnitOfMeasure,
            previousContractLineId: null,
            change: "Added",
          });
        }
      });

      const opportunityObj = {
        name: `${accountName} - Retention - ${DateTime.utc().year}`,
        type: "Renewal",
        stage: "Prospecting",
        currency: contractCurrency,
        products: opportunityProducts,
        closeDate: DateTime.utc().plus({ days: 30 }).toISODate(),
        contractStartDate:
          DateTime.fromISO(contractEndDate).plus({ days: 1 }).toISODate() || "",
        contractTerm: renewalContractTerm,
        noticePeriod: renewalNoticePeriod,
        paymentTerm: renewalPaymentTerm,
        autoRenewal: renewalAutoRenewal,
        previousContractId: contractId,
        annualRecurringRevenue: null,
        nextSteps: "",
        nonRecurringRevenue: null,
        previousAnnualRecurringRevenue: previousAnnualRecurringRevenue,
        accountId: accountId,
        ownerId: currentUser.userId,
      };

      if (currentToken) {
        const opportunityResult = await createOpportunity(
          opportunityObj,
          currentToken
        );
        if (opportunityResult.success) {
          notificationType.success("Success!", opportunityResult.message);
          handleOpenChange(false);
        } else {
          notificationType.error("Error!", opportunityResult.message);
        }
      }
    }
  }

  async function handleTerminationClick(): Promise<void> {
    if (contractStatus === "Pending Termination" && currentToken) {
      const result = await cancelPendingTermination(currentToken, contractId)
      if (result.success) {
        notificationType.success("Success!", result.message)
        handleOpenChange(false)
      } else {
        notificationType.error("Error!", result.message)
      }
    } else if (currentToken) {
      const result = await terminateContract(currentToken, contractId)
      if (result.success) {
        notificationType.success("Success!", result.message)
        handleOpenChange(false)
      } else {
        notificationType.error("Error!", result.message)
      }
    }
  }

  function closeModal() {
    setRenewedProducts([]);
    setAmendmentOpen(false);
    setRenewalOpen(false);
    setTerminationOpen(false)
    setActiveProducts(initialActiveProducts);
    setAvailableProducts(initialAvailableProducts);
    setRenewedProducts(initialRenewedProducts);
    setSelectedIndex(0);
  }

  return (
    <Sheet open={isOpen} onOpenChange={handleOpenChange}>
      <SheetContent side="right" className="w-[900px] !max-w-none">
        {!amendmentOpen && !renewalOpen && !terminationOpen && (
          <>
            <SheetHeader>
              <SheetTitle className="text-2xl mb-4">
                {selectedContract.contractName}
              </SheetTitle>
            </SheetHeader>
            <div className="flex justify-start gap-4 mb-6">
              <button
                onClick={() => {
                  setAmendmentOpen(true);
                }}
                className="bg-violet-500 text-white px-4 py-2 rounded-lg"
              >
                Amendment
              </button>
              <button
                onClick={() => {
                  setRenewalOpen(true);
                }}
                className="bg-violet-400 text-white px-4 py-2 rounded-lg"
              >
                Renewal
              </button>
              <button
                onClick={() => {
                  setTerminationOpen(true);
                }}
                className="bg-red-400 text-white px-4 py-2 rounded-lg"
              >
                {contractStatus !== "Pending Termination"
                  ? "Termination"
                  : "Cancel Termination"}
              </button>
            </div>
            <TabGroup
              selectedIndex={selectedIndex}
              onChange={(index) => setSelectedIndex(index)}
              className={"flex flex-col pt-4"}
            >
              <TabList className={"flex "}>
                {["Details", "Products"].map((label, index) => (
                  <Tab
                    key={label}
                    className={`flex flex-col border-x border-t px-4 py-1 w-32 p-4 items-center justify-center ${
                      selectedIndex === index
                        ? "border-t-violet-500 border-t-4"
                        : "bg-neutral-50"
                    } first:rounded-tl-lg [&:nth-child(2)]:rounded-tr-lg`}
                    onClick={() => setSelectedIndex(index)}
                  >
                    {label}
                  </Tab>
                ))}
                <Tab disabled className={"flex flex-grow border-b"}></Tab>
              </TabList>
              <TabPanels
                className={
                  "flex border-x border-b rounded-bl-lg rounded-br-lg p-4 h-[38rem]"
                }
              >
                <TabPanel className={"flex w-full"}>
                  <div className="w-full">
                    <div className="flex gap-2 mt-6">
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Contract Type</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={contractType}
                          readOnly
                        />
                      </span>
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Contract Status</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={contractStatus}
                          readOnly
                        />
                      </span>
                    </div>
                    <div className="flex gap-2 mt-6">
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Contract Start Date</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={contractStartDate}
                          readOnly
                        />
                      </span>
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Contract End Date</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={contractEndDate}
                          readOnly
                        />
                      </span>
                    </div>
                    <div className="flex gap-2 mt-6">
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Contract Term</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={contractTerm}
                          readOnly
                        />
                      </span>
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Notice Period</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={noticePeriod}
                          readOnly
                        />
                      </span>
                    </div>
                    <div className="flex gap-2 mt-6">
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Auto Renewal</Label>
                        <Checkbox
                          checked={autoRenewal}
                          className="w-4 h-4"
                          disabled
                        />
                      </span>
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Payment Term</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={paymentTerm}
                          readOnly
                        />
                      </span>
                    </div>
                    <div className="flex gap-2 mt-6">
                      <span className="flex flex-col gap-2 w-full">
                        <Label>Contract Currency</Label>
                        <Input
                          className="w-full bg-neutral-100"
                          value={contractCurrency}
                          readOnly
                        />
                      </span>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className={"flex w-full"}>
                  <div className="w-full h-full overflow-y-auto">
                    {contractLines.length > 0 ? (
                      <ul>
                        {contractLines
                          .sort(
                            (a, b) =>
                              DateTime.fromISO(b.endDate).toMillis() -
                              DateTime.fromISO(a.endDate).toMillis()
                          ) // Sort by endDate DESC
                          .map((line, index) => {
                            const isActive =
                              DateTime.fromISO(line.endDate)
                                .toString()
                                .substring(0, 10) ===
                              DateTime.fromISO(contractEndDate)
                                .toString()
                                .substring(0, 10);
                            return (
                              <li
                                key={index}
                                className={`flex flex-col items-start justify-between mb-2 h-auto rounded-2xl p-4 my-4 ${
                                  isActive
                                    ? "bg-violet-200 hover:bg-violet-50"
                                    : "bg-neutral-200/50 hover:bg-blue-50"
                                }`}
                              >
                                <div className="flex gap-8 items-center flex-wrap">
                                  <div className="flex flex-col mb-2">
                                    <Label className="font-bold text-sm">
                                      Product Name
                                    </Label>
                                    <span className="w-48 text-sm">
                                      {line.productName}
                                    </span>
                                  </div>
                                  <div className="flex flex-col text-sm">
                                    <Label className="font-bold">
                                      Start Date
                                    </Label>
                                    <span>
                                      {DateTime.fromISO(
                                        line.startDate
                                      ).toISODate()}
                                    </span>
                                  </div>
                                  <div className="flex flex-col text-sm">
                                    <Label className="font-bold">
                                      End Date
                                    </Label>
                                    <span>
                                      {DateTime.fromISO(
                                        line.endDate
                                      ).toISODate()}
                                    </span>
                                  </div>
                                  <div className="flex flex-col text-sm">
                                    <Label className="font-bold">
                                      Quantity
                                    </Label>
                                    <span>{line.quantity}</span>
                                  </div>
                                  <div className="flex flex-col text-sm">
                                    <Label className="font-bold">
                                      Sales Price
                                    </Label>
                                    <span>
                                      {line.salesPrice
                                        ? `${line.salesPrice.toFixed(
                                            2
                                          )} ${contractCurrency}`
                                        : ""}
                                    </span>
                                  </div>
                                  <div className="flex flex-col text-sm">
                                    <Label className="font-bold">
                                      Overage Price
                                    </Label>
                                    <span>
                                      {line.overagePrice
                                        ? `${line.overagePrice.toFixed(
                                            2
                                          )} ${contractCurrency}`
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    ) : (
                      <p className="text-sm">No contract lines available</p>
                    )}
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
            <div className="flex justify-end gap-4 mt-6">
              <DialogClose
                className="bg-gray-400 text-white px-4 py-2 rounded-lg"
                onClick={() => handleOpenChange(false)}
              >
                Close
              </DialogClose>
            </div>
          </>
        )}
        {amendmentOpen && (
          <div className="flex flex-col h-full justify-between">
            <SheetHeader>
              <SheetTitle className="text-2xl">
                {selectedContract.contractName}
              </SheetTitle>
            </SheetHeader>
            <div className="flex gap-8">
              <span className="flex flex-col gap-2 w-full">
                <Label>Amendment Start Date</Label>
                <Input
                  type="date"
                  value={
                    amendmentStartDate ? amendmentStartDate.split("T")[0] : ""
                  }
                  onChange={(e) => setAmendmentStartDate(e.target.value)}
                />
              </span>
              <span className="flex flex-col gap-2 w-full">
                <Label>Contract End Date</Label>
                <Input
                  className="w-full bg-neutral-100"
                  value={contractEndDate}
                  readOnly
                />
              </span>
            </div>
            <div className="flex flex-col h-[20rem]">
              <h3 className="font-bold pb-2">Active Products</h3>
              <div className="flex h-full w-full p-4 border rounded-lg overflow-y-auto">
                {activeProducts.length > 0 ? (
                  <ul className="flex flex-col w-full">
                    {activeProducts.map((line, index) => (
                      <li
                        key={index}
                        className={`flex items-center justify-between mb-2 h-auto bg-neutral-200/50 rounded-2xl p-4 my-4 hover:bg-blue-50 ${
                          line.isEditing ? "bg-blue-100" : ""
                        }`}
                      >
                        <div className="flex gap-8 items-center flex-wrap">
                          <div className="flex flex-col mb-2">
                            <Label className="font-bold text-sm">
                              Product Name
                            </Label>
                            <span className="w-48 text-sm">
                              {line.productName}
                            </span>
                          </div>
                          <div className="flex flex-col text-sm">
                            <Label className="font-bold">Quantity</Label>
                            {line.isEditing &&
                            line.productType === "License" ? (
                              <input
                                type="number"
                                value={line.quantity}
                                onChange={(e) => handleQuantityChange(e, index)}
                                className="border p-1 rounded text-sm w-20 h-8"
                              />
                            ) : (
                              <span className="w-20 h-8">{line.quantity}</span>
                            )}
                          </div>
                          <div className="flex flex-col text-sm">
                            <Label className="font-bold">Sales Price</Label>
                            {line.isEditing ? (
                              <input
                                type="number"
                                value={line.salesPrice}
                                onChange={(e) =>
                                  handleSalesPriceChange(e, index)
                                }
                                className="border p-1 rounded text-sm w-20 h-8"
                              />
                            ) : (
                              <span className="w-20 h-8">
                                {line.salesPrice
                                  ? `${line.salesPrice.toFixed(
                                      2
                                    )} ${contractCurrency}`
                                  : ""}
                              </span>
                            )}
                          </div>
                          <div className="flex flex-col text-sm">
                            <Label className="font-bold">Overage Price</Label>
                            {line.isEditing ? (
                              <input
                                type="number"
                                value={line.overagePrice}
                                onChange={(e) =>
                                  handleOveragePriceChange(e, index)
                                }
                                className="border p-1 rounded text-sm w-20 h-8"
                              />
                            ) : (
                              <span className="w-20 h-8">
                                {line.overagePrice
                                  ? `${line.overagePrice.toFixed(
                                      2
                                    )} ${contractCurrency}`
                                  : ""}
                              </span>
                            )}
                          </div>
                        </div>
                        <button
                          onClick={() => handleEditClick(index)}
                          className="ml-2 justify-end"
                        >
                          {line.isEditing ? (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5 stroke-violet-500"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                              </svg>
                            </>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5 stroke-violet-500"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                              />
                            </svg>
                          )}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-sm">No contract lines available</p>
                )}
              </div>
            </div>
            <div className="flex flex-col h-[20rem]">
              <h3 className="font-bold pb-2">Available Products</h3>
              <div className="flex h-full w-full p-4 border rounded-lg overflow-y-auto">
                {availableProducts.length > 0 ? (
                  <ul className="flex flex-col w-full">
                    {availableProducts.map((line, index) => (
                      <li
                        key={index}
                        className={`flex items-center justify-between mb-2 h-auto bg-neutral-200/50 rounded-2xl p-4 my-4 hover:bg-blue-50 ${
                          line.isAdded ? "bg-blue-100" : ""
                        }`}
                      >
                        <div className="flex gap-4 items-center flex-wrap">
                          <input
                            type="checkbox"
                            checked={line.isAdded}
                            onChange={() => handleCheckboxChange(index)}
                            className="mr-4"
                          />
                          <div className="flex flex-col mb-2">
                            <Label className="font-bold text-sm">
                              Product Name
                            </Label>
                            <span className="w-48 text-sm">
                              {line.productName}
                            </span>
                          </div>
                          <div className="flex flex-col text-sm">
                            <Label className="font-bold">Quantity</Label>
                            {line.isAdded && line.productType !== "Module" ? (
                              <input
                                type="number"
                                value={line.quantity}
                                onChange={(e) =>
                                  handleAvailableQuantityChange(e, index)
                                }
                                className="border p-1 rounded text-sm w-20 h-8"
                              />
                            ) : (
                              <span className="h-8 w-16">{line.quantity}</span>
                            )}
                          </div>
                          <div className="flex flex-col text-sm">
                            <Label className="font-bold">Sales Price</Label>
                            {line.isAdded ? (
                              <input
                                type="number"
                                value={line.salesPrice || ""}
                                onChange={(e) =>
                                  handleAvailableSalesPriceChange(e, index)
                                }
                                className="border p-1 rounded text-sm w-20 h-8"
                              />
                            ) : (
                              <span className="w-20 h-8">
                                {line.salesPrice
                                  ? `${line.salesPrice.toFixed(
                                      2
                                    )} ${contractCurrency}`
                                  : ""}
                              </span>
                            )}
                          </div>
                          <div className="flex flex-col text-sm">
                            <Label className="font-bold">Overage Price</Label>
                            {line.isAdded &&
                            line.revenueType === "Recurring" ? (
                              <input
                                type="number"
                                value={line.overagePrice}
                                onChange={(e) =>
                                  handleAvailableOveragePriceChange(e, index)
                                }
                                className="border p-1 rounded text-sm w-20 h-8"
                              />
                            ) : (
                              <span className="h-8 w-16">
                                {line.overagePrice
                                  ? `${line.overagePrice.toFixed(
                                      2
                                    )} ${contractCurrency}`
                                  : ""}
                              </span>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-sm">No contract lines available</p>
                )}
              </div>
            </div>
            <div className="flex gap-4">
              <div className="self-start">
                <button
                  className={`mt-4 ${
                    updateDisabled
                      ? "bg-neutral-300 cursor-not-allowed"
                      : "bg-violet-500"
                  } text-white px-4 py-2 rounded`}
                  disabled={updateDisabled}
                  onClick={handleCreateOpportunity}
                >
                  Save & Close
                </button>
              </div>
              <button
                className="mt-4 bg-gray-400 text-white px-4 py-2 rounded"
                onClick={() => {
                  closeModal();
                }}
              >
                Back
              </button>
            </div>
          </div>
        )}

        {/* RENEWAL */}
        {renewalOpen && (
          <div className="flex flex-col h-full justify-between">
            <TabGroup>
              <SheetHeader>
                <SheetTitle className="text-2xl mb-4">
                  {selectedContract.contractName}
                </SheetTitle>
              </SheetHeader>
              <TabList className={"flex "}>
                {["Details", "Products"].map((label, index) => (
                  <Tab
                    key={label}
                    className={`flex flex-col border-x border-t px-4 py-1 w-32 p-4 items-center justify-center ${
                      selectedIndex === index
                        ? "border-t-violet-500 border-t-4"
                        : "bg-neutral-50"
                    } first:rounded-tl-lg [&:nth-child(3)]:rounded-tr-lg`}
                    onClick={() => setSelectedIndex(index)}
                  >
                    {label === "Details" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                        />
                      </svg>
                    )}

                    {label === "Products" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                        />
                      </svg>
                    )}
                    {label}
                  </Tab>
                ))}
                <Tab disabled className={"flex flex-grow border-b"}></Tab>
              </TabList>

              <TabPanels
                className={
                  "flex flex-col border-x border-b rounded-bl-lg rounded-br-lg p-4 "
                }
              >
                <TabPanel>
                  <div className="flex gap-2 mt-6">
                    <span className="flex flex-col gap-2 w-full">
                      <Label>Renewal Start Date</Label>
                      <Input
                        type="date"
                        className="w-1/2 bg-neutral-100"
                        value={
                          DateTime.fromISO(contractEndDate)
                            .plus({ days: 1 })
                            .toISODate() || ""
                        }
                        readOnly
                      />
                    </span>
                    <span className="flex flex-col gap-2 w-full">
                      <Label>Contract Term</Label>
                      <Select
                        value={renewalContractTerm?.toString() || undefined}
                        onValueChange={(value) => {
                          setRenewalContractTerm(parseInt(value));
                        }}
                      >
                        <SelectTrigger className="w-1/2">
                          <SelectValue placeholder="Pick a term.." />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="12">12 months</SelectItem>
                          <SelectItem value="24">24 months</SelectItem>
                          <SelectItem value="36">36 months</SelectItem>
                        </SelectContent>
                      </Select>
                      {renewalContractError && !renewalContractTerm && (
                        <p className="text-red-500 text-xs pl-1">
                          Please fill in Contract Term to generate PDF.
                        </p>
                      )}
                    </span>
                  </div>
                  <div className="flex gap-2 mt-6">
                    <span className="flex flex-col gap-2 w-full">
                      <Label>Notice Period</Label>
                      <Select
                        value={renewalNoticePeriod?.toString() ?? ""}
                        onValueChange={(value: string) =>
                          setRenewalNoticePeriod(parseInt(value))
                        }
                      >
                        <SelectTrigger className="w-1/2">
                          <SelectValue placeholder="Pick a term.." />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="60">60 days</SelectItem>
                          <SelectItem value="90">90 days</SelectItem>
                          <SelectItem value="120">120 days</SelectItem>
                        </SelectContent>
                      </Select>
                      {renewalContractError && !renewalNoticePeriod && (
                        <p className="text-red-500 text-xs pl-1">
                          Please fill in Notice Period to generate PDF.
                        </p>
                      )}
                    </span>
                    <span className="flex flex-col gap-2 w-full">
                      <Label htmlFor="type">Payment Term</Label>
                      <Select
                        value={renewalPaymentTerm?.toString() ?? ""}
                        onValueChange={(value: string) =>
                          setRenewalPaymentTerm(parseInt(value))
                        }
                      >
                        <SelectTrigger className="w-1/2">
                          <SelectValue placeholder="30" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="30">30 days</SelectItem>
                          <SelectItem value="45">45 days</SelectItem>
                          <SelectItem value="60">60 days</SelectItem>
                        </SelectContent>
                      </Select>
                      {renewalContractError && !renewalPaymentTerm && (
                        <p className="text-red-500 text-xs pl-1">
                          Please fill in Payment Term to generate PDF.
                        </p>
                      )}
                    </span>
                  </div>
                  <div className={`flex gap-2 mt-6`}>
                    <span className="flex flex-col gap-2 w-1/2">
                      <Label htmlFor="type">Auto Renewal</Label>
                      <div className="flex items-center py-2">
                        <input
                          type="checkbox"
                          checked={renewalAutoRenewal}
                          onChange={(e) =>
                            setRenewalAutoRenwal(e.target.checked)
                          }
                          className="w-4 h-4"
                        />
                      </div>
                    </span>
                    <span className="flex flex-col gap-2 w-1/2">
                      <Label htmlFor="type">Currency</Label>
                      <Input
                        disabled
                        value={contractCurrency || undefined}
                        className="w-1/2"
                      ></Input>
                    </span>
                  </div>
                </TabPanel>

                <TabPanel>
                  {/* Renewable Products */}
                  <div className="flex flex-col h-[20rem]">
                    <h3 className="font-bold pb-2">Renewable Products</h3>
                    <div className="flex h-full w-full p-4 border rounded-lg overflow-y-auto">
                      {renewedProducts.length > 0 ? (
                        <ul className="flex flex-col w-full">
                          {renewedProducts.map((product, index) => (
                            <li
                              key={product.productId}
                              className={`flex items-center justify-between mb-2 h-auto bg-neutral-200/50 rounded-2xl p-4 my-4 hover:bg-blue-50 ${
                                product.isEditing ? "bg-blue-100" : ""
                              }`}
                            >
                              <div className="flex gap-8 items-center flex-wrap">
                                {/* Checkbox for renewable status */}
                                <input
                                  type="checkbox"
                                  checked={
                                    product.isRenewing ||
                                    product.productType === "License"
                                  }
                                  disabled={product.productType === "License"}
                                  onChange={(e) =>
                                    handleProductChange(
                                      e,
                                      product,
                                      index,
                                      "Renewable",
                                      "checkbox"
                                    )
                                  }
                                  className="mr-4"
                                />

                                <div className="flex flex-col mb-2">
                                  <Label className="font-bold text-sm">
                                    Product Name
                                  </Label>
                                  <span className="w-48 text-sm">
                                    {product.productName}
                                  </span>
                                </div>

                                {/* Quantity Field */}
                                <div className="flex flex-col text-sm">
                                  <Label className="font-bold">Quantity</Label>
                                  <input
                                    type="number"
                                    disabled={product.productType === "Module"}
                                    value={product.quantity}
                                    onChange={(e) =>
                                      handleProductChange(
                                        e,
                                        product,
                                        index,
                                        "Renewable",
                                        "quantity"
                                      )
                                    }
                                    className="border p-1 rounded text-sm w-20 h-8"
                                  />
                                </div>

                                {/* Sales Price Field */}
                                <div className="flex flex-col text-sm">
                                  <Label className="font-bold">
                                    Sales Price
                                  </Label>
                                  <input
                                    type="number"
                                    value={product.salesPrice || ""}
                                    onChange={(e) =>
                                      handleProductChange(
                                        e,
                                        product,
                                        index,
                                        "Renewable",
                                        "salesPrice"
                                      )
                                    }
                                    className="border p-1 rounded text-sm w-20 h-8"
                                  />
                                </div>

                                {/* Overage Price Field (always editable for Renewable) */}
                                <div className="flex flex-col text-sm">
                                  <Label className="font-bold">
                                    Overage Price
                                  </Label>
                                  <input
                                    type="number"
                                    value={product.overagePrice}
                                    onChange={(e) =>
                                      handleProductChange(
                                        e,
                                        product,
                                        index,
                                        "Renewable",
                                        "overagePrice"
                                      )
                                    }
                                    className="border p-1 rounded text-sm w-20 h-8"
                                  />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm">No contract lines available</p>
                      )}
                    </div>
                  </div>

                  {/* Available Products */}
                  <div className="flex flex-col h-[20rem] mt-4">
                    <h3 className="font-bold pb-2">Available Products</h3>
                    <div className="flex h-full w-full p-4 border rounded-lg overflow-y-auto">
                      {availableProducts.length > 0 ? (
                        <ul className="flex flex-col w-full">
                          {availableProducts.map((line, index) => (
                            <li
                              key={index}
                              className={`flex items-center justify-between mb-2 h-auto bg-neutral-200/50 rounded-2xl p-4 my-4 hover:bg-blue-50 ${
                                line.isAdded ? "bg-blue-100" : ""
                              }`}
                            >
                              <div className="flex gap-4 items-center flex-wrap">
                                <input
                                  type="checkbox"
                                  checked={line.isAdded}
                                  onChange={() => handleCheckboxChange(index)}
                                  className="mr-4"
                                />
                                <div className="flex flex-col mb-2">
                                  <Label className="font-bold text-sm">
                                    Product Name
                                  </Label>
                                  <span className="w-48 text-sm">
                                    {line.productName}
                                  </span>
                                </div>
                                <div className="flex flex-col text-sm">
                                  <Label className="font-bold">Quantity</Label>
                                  {line.isAdded &&
                                  line.productType !== "Module" ? (
                                    <input
                                      type="number"
                                      value={line.quantity}
                                      onChange={(e) =>
                                        handleAvailableQuantityChange(e, index)
                                      }
                                      className="border p-1 rounded text-sm w-20 h-8"
                                    />
                                  ) : (
                                    <span className="h-8 w-16">
                                      {line.quantity}
                                    </span>
                                  )}
                                </div>
                                <div className="flex flex-col text-sm">
                                  <Label className="font-bold">
                                    Sales Price
                                  </Label>
                                  {line.isAdded ? (
                                    <input
                                      type="number"
                                      value={line.salesPrice || ""}
                                      onChange={(e) =>
                                        handleAvailableSalesPriceChange(
                                          e,
                                          index
                                        )
                                      }
                                      className="border p-1 rounded text-sm w-20 h-8"
                                    />
                                  ) : (
                                    <span className="w-20 h-8">
                                      {line.salesPrice
                                        ? `${line.salesPrice.toFixed(
                                            2
                                          )} ${contractCurrency}`
                                        : ""}
                                    </span>
                                  )}
                                </div>
                                <div className="flex flex-col text-sm">
                                  <Label className="font-bold">
                                    Overage Price
                                  </Label>
                                  {line.isAdded &&
                                  line.revenueType === "Recurring" ? (
                                    <input
                                      type="number"
                                      value={line.overagePrice}
                                      onChange={(e) =>
                                        handleAvailableOveragePriceChange(
                                          e,
                                          index
                                        )
                                      }
                                      className="border p-1 rounded text-sm w-20 h-8"
                                    />
                                  ) : (
                                    <span className="h-8 w-16">
                                      {line.overagePrice
                                        ? `${line.overagePrice.toFixed(
                                            2
                                          )} ${contractCurrency}`
                                        : ""}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm">No contract lines available</p>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
            <div className="flex items-end gap-4">
              <div className="self-start">
                <button
                  className={`mt-4 ${
                    updateDisabled
                      ? "bg-neutral-300 cursor-not-allowed"
                      : "bg-violet-500"
                  } text-white px-4 py-2 rounded`}
                  disabled={updateDisabled}
                  onClick={handleCreateOpportunity}
                >
                  Save & Close
                </button>
              </div>
              <button
                className="mt-4 bg-gray-400 text-white px-4 py-2 rounded"
                onClick={() => {
                  closeModal();
                }}
              >
                Back
              </button>
            </div>
          </div>
        )}
        {terminationOpen && (
          <div className="flex flex-col h-full justify-between">
            <SheetHeader>
              <SheetTitle className="text-2xl">
                {selectedContract.contractName}
              </SheetTitle>
            </SheetHeader>
            <div className="flex flex-col flex-1 gap-8">
              <div className="flex pt-4 gap-8">
                <span className="flex flex-col gap-2 w-1/2">
                  <Label>Termination Date</Label>
                  <Input
                    type="date"
                    value={contractEndDate}
                    readOnly
                    disabled
                  />
                </span>
              </div>

              {/* Conditional section based on contract status */}
              {selectedContract.contractStatus === "Pending Termination" ? (
                <div className="flex flex-col gap-2 w-full">
                  <Label>Cancel Pending Termination</Label>
                  <p className="text-sm text-gray-600">
                    This contract is currently in a{" "}
                    <strong>Pending Termination</strong> state. If the customer
                    wishes to continue the contract, you can cancel the pending
                    termination by clicking the button below.
                  </p>
                </div>
              ) : (
                <div className="flex flex-col gap-2 w-full">
                  <Label>Contract Termination Information</Label>
                  <p className="text-sm text-gray-600">
                    Pressing <strong>Save & Close</strong> will set this
                    contract to
                    <strong> Pending Termination</strong>. Services and billing
                    will automatically stop on{" "}
                    <strong>{contractEndDate}</strong>. If the customer wishes
                    to continue the contract, they must{" "}
                    <strong>Cancel Termination</strong> before the end date.
                  </p>
                </div>
              )}
            </div>

            <div className="flex gap-4">
              <div className="self-start">
                <button
                  className={`mt-4 bg-violet-500 text-white px-4 py-2 rounded`}
                  onClick={handleTerminationClick}
                >
                  Save & Close
                </button>
              </div>
              <button
                className="mt-4 bg-gray-400 text-white px-4 py-2 rounded"
                onClick={closeModal}
              >
                Back
              </button>
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default ContractDetails;
